import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import {
  DocumentType,
  DocumentTypeCreateInput,
  DocumentTypeUpdateInput,
} from "core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";

import DocumentTypeFormFields from "./DocumentTypeFormFields";
import { useCurrentWorkspace } from "../../../../../hooks/UseCurrentWorkspace";
import {
  useCreateDocumentTypeMutation,
  useUpdateDocumentTypeMutation,
} from "../../../../../redux/apis/document";

import "../../../../../assets/css/document.css";

interface Properties {
  workspaceId?: number;
  documentType?: DocumentType;
}

interface DocumentTypeInput {
  name: string;
  path: string;
  slug: string;
}

export const getEditorContent = (content: string) => content;

const DocumentTypeForm: React.FC<Properties> = (properties) => {
  const { workspaceId, documentType } = properties;

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>();
  const { t } = useTranslation("docs");
  const navigate = useNavigate();

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const [template, setTemplate] = useState(
    documentType ? documentType.template : "",
  );

  const [createDocumentType, { isLoading: isCreatingDocumentType }] =
    useCreateDocumentTypeMutation();

  const [UpdateDocumentType] = useUpdateDocumentTypeMutation();

  const formValidationSchema = z.object({
    name: z.string().min(1),
    path: z.string().min(1),
    slug: z.string().min(1),
  });

  const onSubmit = async (data: DocumentTypeInput) => {
    const documentTypeInput: DocumentTypeCreateInput | DocumentTypeUpdateInput =
      {
        ...data,
        template: template,
      };

    if (!documentType) {
      let response = (await createDocumentType({
        data: documentTypeInput as DocumentTypeCreateInput,
        workspaceId: workspaceId!,
      })) as any;

      if ("error" in response) {
        if (
          response.error.status === 409 &&
          response.error.data.error === "ERROR_SLUG_ALREADY_EXISTS"
        ) {
          setFieldErrors({
            slug: t("docs-types.message.error.docs-type-slug-already-exists"),
          });

          return;
        }

        toast.error(t("docs-types.message.error.default"));
      } else {
        toast.success(t("docs-types.message.success.docs-types-added"));
        navigate(`/workspaces/${currentWorkspace.slug}/settings/docs`);
      }
    } else {
      let response = (await UpdateDocumentType({
        data: documentTypeInput as DocumentTypeUpdateInput,
        workspaceId: documentType.workspaceId!,
        id: documentType.id,
      })) as any;

      if ("error" in response) {
        toast.error(t("docs-types.message.error.update-default"));
      } else {
        toast.success(t("docs-types.message.success.docs-types-updated"));
        navigate(`/workspaces/${currentWorkspace.slug}/settings/docs`);
      }
    }
  };

  return (
    <div className="document-types-form-wrapper">
      <Provider
        onSubmit={onSubmit}
        validationSchema={formValidationSchema}
        defaultValues={
          documentType
            ? {
                name: documentType.name,
                path: documentType.path,
                slug: documentType.slug,
              }
            : {
                name: "",
                path: "",
                slug: "",
              }
        }
      >
        <DocumentTypeFormFields
          fieldErrors={fieldErrors}
          isSubmitting={isCreatingDocumentType}
          template={documentType && documentType.template!}
          setTemplate={setTemplate}
          update={documentType ? true : false}
        />
      </Provider>
    </div>
  );
};

export default DocumentTypeForm;
