import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

const encodeURIParameter = <T>(argument: T) => {
  return !argument ? undefined : JSON.stringify(argument);
};

const isFetchBaseQueryError = (
  error: unknown,
): error is FetchBaseQueryError => {
  return typeof error === "object" && error != null && "status" in error;
};

export { encodeURIParameter, isFetchBaseQueryError };
