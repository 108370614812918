import {
  Password,
  Select,
  TextInput,
  useFormContext,
  useWatch,
} from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { REGISTRY_PROVIDER } from "core";
import { useEffect } from "react";

const RegistryFormFields = () => {
  const { t } = useTranslation("services");
  const watchRegistryProvider: string = useWatch({ name: "registry.provider" });

  const { getFieldState, register, setValue } = useFormContext();

  useEffect(() => {
    if (watchRegistryProvider === undefined) {
      setValue("registry", null);
    }
  }, [watchRegistryProvider, setValue]);

  const renderRegistryTypeFields = () => {
    switch (watchRegistryProvider) {
      case REGISTRY_PROVIDER.DOCKERHUB:
        return (
          <>
            <TextInput
              label={t("form.fields.registry.dockerhub.registryUrl")}
              name="registry.url"
            />
            <TextInput
              label={t("form.fields.registry.dockerhub.username")}
              name="registry.authentication.username"
            />
            <Password
              label={t("form.fields.registry.dockerhub.password")}
              name="registry.authentication.password"
              register={register}
              getFieldState={getFieldState}
            />
          </>
        );

      case REGISTRY_PROVIDER.AWS_ECR:
        return (
          <>
            <TextInput
              className="registry-form-input"
              label={t("form.fields.registry.awsEcr.registryUrl")}
              name="registry.url"
            />
            <TextInput
              className="registry-form-input"
              label={t("form.fields.registry.awsEcr.accessKeyId")}
              name="registry.authentication.awsAccessKeyId"
            />
            <Password
              className="registry-form-input"
              label={t("form.fields.registry.awsEcr.secretAccessKey")}
              name="registry.authentication.awsSecretAccessKey"
              register={register}
              getFieldState={getFieldState}
            />
            <TextInput
              className="registry-form-input"
              label={t("form.fields.registry.awsEcr.region")}
              name="registry.authentication.awsRegion"
            />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Select
        label={t("form.fields.registry.provider.title")}
        name="registry.provider"
        options={[
          {
            label: t("form.fields.registry.provider.labels.aws-ecr"),
            value: REGISTRY_PROVIDER.AWS_ECR,
          },
          {
            label: t("form.fields.registry.provider.labels.dockerhub"),
            value: REGISTRY_PROVIDER.DOCKERHUB,
          },
        ]}
        placeholder={t("form.fields.registry.provider.placeholder")}
      />

      {watchRegistryProvider && (
        <>
          {watchRegistryProvider === REGISTRY_PROVIDER.AWS_ECR ? (
            <label>{t("form.fields.registry.awsEcr.connectionTitle")}</label>
          ) : (
            <label>{t("form.fields.registry.dockerhub.connectionTitle")}</label>
          )}
          <div className="registry-form">{renderRegistryTypeFields()}</div>
        </>
      )}
    </>
  );
};

export default RegistryFormFields;
