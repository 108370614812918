import { Edge, type Node } from "@xyflow/react";
import { ReleaseApp, Service } from "core";

import { EDGE_TYPES } from "../../../constants";

export const DEFAULT_LABEL_AREA_WIDTH = 0;
export const DEFAULT_OFFSET_X = 220;
export const DEFAULT_OFFSET_Y = 200;

interface Position {
  x: number;
  y: number;
}

interface Nodebuilder {
  t: (label: string) => string;
  release?: any;
  appsPosition?: Position;
  apisPosition?: Position;
  servicesPosition?: Position;
}

export interface AppsService {
  appId: number;
  serviceId: number;
}

export const buildGraphNodesAndEdges = ({
  release,
  t,
  apisPosition,
  appsPosition,
  servicesPosition,
}: Nodebuilder) => {
  const defaultAppsPosition = appsPosition || {
    x: 20,
    y: DEFAULT_OFFSET_Y * 0,
  };

  const defaultApisPosition = apisPosition || {
    x: 20,
    y: DEFAULT_OFFSET_Y * 1,
  };

  const defaultServicesPosition = servicesPosition || {
    x: 20,
    y: DEFAULT_OFFSET_Y * 2,
  };

  const ChildrenNodePosition = {
    x: 15,
    yCard: 50,
    yHeader: 5,
  };

  const nodes: Node[] = [];
  let yOffset = 0;

  const edges: Edge[] = [];

  const releaseApps: ReleaseApp[] = release?.apps?.filter(
    (app: ReleaseApp) => app?.type === "apps",
  );
  const releaseApis: ReleaseApp[] = release?.apps?.filter(
    (app: ReleaseApp) => app.type === "apis",
  );
  const releaseServices: Service[] = release?.services || [];

  const appsServices = (release?.appsServices as AppsService[]) || [];

  const appsPos = appsPosition || { x: defaultAppsPosition.x, y: yOffset };

  nodes.push({
    id: "app-group",
    data: { label: "apps" },
    position: appsPos,
    type: "groupWrapper",
    selectable: false,
  });
  nodes.push({
    id: "apps",
    type: "header",
    data: { label: t(`modules.apps`), type: "apps" },
    position: { x: ChildrenNodePosition.x, y: ChildrenNodePosition.yHeader },
    parentId: "app-group",
  });

  releaseApps.forEach((app, i) => {
    const appsService =
      appsServices?.filter((appService) => appService.appId === app.id) || [];

    nodes.push({
      id: app.slug,
      data: { app, appsService, releaseApis },
      position: {
        x:
          ChildrenNodePosition.x! +
          i * DEFAULT_OFFSET_X +
          DEFAULT_LABEL_AREA_WIDTH,
        y: ChildrenNodePosition.yCard,
      },
      type: "appCard",
      parentId: "app-group",
    });
  });

  yOffset += DEFAULT_OFFSET_Y;

  const apisPos = apisPosition || { x: defaultApisPosition.x, y: yOffset };

  nodes.push({
    id: "api-group",
    data: { label: "api" },
    position: apisPos,
    type: "groupWrapper",
    selectable: false,
  });
  nodes.push({
    id: "apis",
    type: "header",
    data: { label: t(`modules.apis`), type: "apis" },
    position: { x: ChildrenNodePosition.x, y: ChildrenNodePosition.yHeader },
    parentId: "api-group",
  });

  releaseApis.forEach((api, i) => {
    const appsService =
      appsServices?.filter((appService) => appService.appId === api.id) || [];

    nodes.push({
      id: api.slug,
      data: { app: api, appsService, releaseApps },
      position: {
        x:
          ChildrenNodePosition.x! +
          i * DEFAULT_OFFSET_X +
          DEFAULT_LABEL_AREA_WIDTH,
        y: ChildrenNodePosition.yCard,
      },
      type: "appCard",
      parentId: "api-group",
    });
  });

  yOffset += DEFAULT_OFFSET_Y;

  const servicesPos = servicesPosition || {
    x: defaultServicesPosition.x,
    y: yOffset,
  };

  nodes.push({
    id: "service-group",
    data: { label: "service" },
    position: servicesPos,
    type: "groupWrapper",
    selectable: false,
  });
  nodes.push({
    id: "services",
    type: "header",
    data: { label: t(`modules.services`), type: "services" },
    position: { x: ChildrenNodePosition.x, y: ChildrenNodePosition.yHeader },
    parentId: "service-group",
  });

  releaseServices.forEach((service, i) => {
    const appsService =
      appsServices?.filter(
        (appService) => appService.serviceId === service.id,
      ) || [];

    nodes.push({
      id: service.slug,
      data: { service, appsService },
      position: {
        x:
          ChildrenNodePosition.x! +
          i * DEFAULT_OFFSET_X +
          DEFAULT_LABEL_AREA_WIDTH,
        y: ChildrenNodePosition.yCard,
      },
      type: "serviceCard",
      parentId: "service-group",
    });
  });

  if (releaseApps.length > 0 && releaseApis.length > 0) {
    releaseApps.forEach((app) => {
      releaseApis.forEach((api) => {
        edges.push({
          id: `${app.slug}-${api.slug}`,
          source: app.slug,
          target: api.slug,
        });
      });
    });
  }

  if (appsServices && appsServices.length > 0) {
    appsServices.forEach((appService: any) => {
      const targetApp = [...releaseApps, ...releaseApis]?.find((app) => {
        return app.id === appService.appId;
      });
      const destinationService = releaseServices?.find((service) => {
        return service.id === appService.serviceId;
      });

      if (
        targetApp &&
        destinationService &&
        targetApp.slug &&
        destinationService.slug
      ) {
        edges.push({
          id: `${targetApp.slug}-${destinationService.slug}`,
          data: {
            releaseAppId: targetApp.id,
            workspaceServiceId: destinationService.id,
            workspaceServiceName: destinationService.name,
          },
          source: targetApp.slug,
          target: destinationService.slug,
          type: EDGE_TYPES.SERVICE_EDGE,
        });
      }
    });
  }

  return { nodes, edges };
};
