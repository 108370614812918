import {
  Select,
  Textarea,
  TextInput,
  useFormContext,
} from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button, SubmitButton } from "@dzangolab/react-ui";
import { ReleaseCreateInput, ReleaseType } from "core";
import { useEffect } from "react";

interface Properties {
  actionLabel?: string;
  fieldErrors?: Record<string, string>;
  handleCancel: () => void;
  isLoading: boolean;
  releaseTypes?: ReleaseType[];
}

const ReleaseFormFields: React.FC<Properties> = (properties) => {
  const { actionLabel, fieldErrors, handleCancel, isLoading, releaseTypes } =
    properties;
  const { t } = useTranslation("release");

  const {
    formState: { errors },
    getFieldState,
    register,
    setError,
    setValue,
    watch,
  } = useFormContext<ReleaseCreateInput>();

  const watchName: string = watch("name");

  const getErrorMessage = (field: keyof ReleaseCreateInput): string => {
    return (errors[field]?.message as string) || "";
  };

  useEffect(() => {
    if (fieldErrors?.slug) {
      setError("slug", {
        type: "manual",
        message: fieldErrors.slug,
      });
    }
  }, [fieldErrors, setError]);

  useEffect(() => {
    const { isDirty } = getFieldState("slug");

    if (!isDirty) {
      const generatedSlug = watchName
        ?.trim()
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^[-~!@#$%^&*()]+/, "")
        .replace(/[-~!@#$%^&*()]+$/, "");

      setValue("slug", generatedSlug);
    }
  }, [getFieldState, setValue, watchName]);

  return (
    <>
      <TextInput
        label={t("form.fields.name")}
        name="name"
        errorMessage={getErrorMessage("name")}
      />
      <TextInput
        label={t("form.fields.slug")}
        name="slug"
        errorMessage={getErrorMessage("slug")}
      />
      <Textarea
        label={t("form.fields.description")}
        name="description"
        errorMessage={getErrorMessage("description")}
      />
      <Select
        label={t("form.fields.type.label")}
        name="typeId"
        options={
          releaseTypes?.map((releaseType) => ({
            label: releaseType.name,
            value: releaseType.id,
          })) ?? []
        }
        placeholder={t("form.fields.type.placeholder")}
      />
      <div className="checkbox-wrapper">
        <input
          id="isMultitenant"
          type="checkbox"
          {...register("isMultitenant")}
        />
        <label htmlFor="isMultitenant">{t("form.fields.isMultitenant")}</label>
      </div>
      <Button
        label={t("form.actions.cancel")}
        type="button"
        onClick={handleCancel}
        severity="secondary"
        variant="outlined"
      />
      <SubmitButton
        label={actionLabel ?? t("form.actions.submit")}
        loading={isLoading}
      />
    </>
  );
};

export default ReleaseFormFields;
