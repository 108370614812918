import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Page } from "@dzangolab/react-ui";
import { useNavigate, useParams } from "react-router-dom";

import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";

const AddFirstTermPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("glossary");
  const parameters = useParams();

  const glossaryId = parseInt(parameters.id ?? "");

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const onAddFirstTerm = () => {
    if (currentWorkspace && currentWorkspace.slug && glossaryId) {
      navigate(
        `/workspaces/${currentWorkspace.slug}/glossary/${glossaryId}/entry/new`,
      );
    }
  };

  return (
    <Page className="add-first-term-page" title={t("title")}>
      <div className="center">
        <div>
          <p>{t("pages.add-first-item.title")}</p>
          <Button
            label={t("pages.add-first-item.actions.add-first-term")}
            variant="filled"
            onClick={onAddFirstTerm}
          />
        </div>
      </div>
    </Page>
  );
};

export default AddFirstTermPage;
