import { Milkdown, MilkdownProvider } from "@milkdown/react";
import { ProsemirrorAdapterProvider } from "@prosemirror-adapter/react";
import React from "react";

import MilkdownToolbar from "./MarkdownEditor/toolbar";
import { useMarkdownEditor } from "./MarkdownEditor/use-markdown-editor";

import "./editor.css";

interface CollabProperties {
  userName: string;
  roomId: string;
  websocketUrl: string;
  onDocumentSave: (content: string) => void;
}
interface EditorProperties {
  content?: string;
  onCollab?: CollabProperties;
  editable: boolean;
  setEditorContent?: React.Dispatch<React.SetStateAction<string | null>>;
}

const Editor = ({
  content,
  onCollab,
  editable,
  setEditorContent,
}: EditorProperties) => {
  const { get } = useMarkdownEditor(
    content || "",
    editable,
    onCollab,
    setEditorContent,
  );

  return (
    <>
      {editable && (
        <MilkdownToolbar get={get} onSave={onCollab?.onDocumentSave} />
      )}
      <Milkdown />
    </>
  );
};

const MilkdownEditor: React.FC<EditorProperties> = ({
  content,
  onCollab,
  editable,
  setEditorContent,
}: EditorProperties) => {
  return (
    <MilkdownProvider>
      <ProsemirrorAdapterProvider>
        <Editor
          content={content}
          editable={editable}
          onCollab={onCollab}
          setEditorContent={setEditorContent}
        />
      </ProsemirrorAdapterProvider>
    </MilkdownProvider>
  );
};

export default MilkdownEditor;
