import baseApi, { TagsEnum } from "./base-api";

import type {
  BranchMatchingRule,
  Repository,
  RepositoryCreateInput,
} from "core";

const repositoriesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRepositories: build.query<Repository[], { workspaceId: number }>({
      query: ({ workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/repositories`,
        };
      },
      providesTags: [TagsEnum.Repositories],
    }),

    getRepository: build.query<Repository, { workspaceId: number; id: number }>(
      {
        query: ({ workspaceId, id }) => {
          return {
            url: `workspaces/${workspaceId}/repositories/${id}`,
          };
        },
      },
    ),

    getRepositoryBranches: build.query<
      { branches: string[] },
      { workspaceId: number; id: number }
    >({
      query: ({ workspaceId, id }) => {
        return {
          url: `workspaces/${workspaceId}/repositories/${id}/branches`,
        };
      },
    }),

    getRepositoryBranchRules: build.query<
      BranchMatchingRule[],
      { workspaceId: number; id: number }
    >({
      query: ({ workspaceId, id }) => {
        return {
          url: `workspaces/${workspaceId}/repositories/${id}/branch-rules`,
        };
      },
    }),

    createRepository: build.mutation<Repository, RepositoryCreateInput>({
      query: (data: RepositoryCreateInput) => {
        return {
          url: `workspaces/${data.workspaceId}/repositories`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.Repositories],
    }),

    validateCredentials: build.query({
      query: (data: {
        url: string;
        credential: {
          accessToken: string;
          username: string;
        };
      }) => {
        return {
          url: `/repositories/validate-credential`,
          method: "POST",
          body: data,
        };
      },
    }),

    updateRepository: build.mutation<
      Repository,
      { id: number; data: RepositoryCreateInput }
    >({
      query: ({ id, data }) => {
        return {
          url: `workspaces/${data.workspaceId}/repositories/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.Repositories],
    }),

    deleteRepository: build.mutation<
      any,
      { workspaceId: number; repositoryId: number }
    >({
      query: ({ workspaceId, repositoryId }) => {
        return {
          url: `workspaces/${workspaceId}/repositories/${repositoryId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [TagsEnum.Repositories],
    }),
  }),
});

export const {
  useCreateRepositoryMutation,
  useDeleteRepositoryMutation,
  useLazyGetRepositoriesQuery,
  useLazyGetRepositoryBranchesQuery,
  useLazyGetRepositoryBranchRulesQuery,
  useLazyGetRepositoryQuery,
  useLazyValidateCredentialsQuery,
  useUpdateRepositoryMutation,
} = repositoriesApi;
export default repositoriesApi;
