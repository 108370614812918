import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Workspace } from "core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";

import WorkspaceFormFields from "./WorkspaceFormFields";
import { ROUTES } from "../../constants";
import {
  useCreateWorkspaceMutation,
  useUpdateWorkspaceMutation,
} from "../../redux/apis/workspaces";

interface Properties {
  workspace?: Workspace;
}

interface ErrorResponse {
  error: {
    status: number;
    data: {
      error: string;
      message: string;
    };
  };
}

interface WorkspaceResponse {
  data: Workspace;
}

const WorkspaceForm = ({ workspace }: Properties) => {
  const { t } = useTranslation("workspaceForm");
  const navigate = useNavigate();

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>();

  const [triggerAdd, { isLoading: isLoadingAdd }] =
    useCreateWorkspaceMutation();

  const [triggerUpdate] = useUpdateWorkspaceMutation();

  const workspaceValidationSchema = z.object({
    name: z
      .string()
      .min(1, t("form.validations.name.required"))
      .max(50, t("form.validations.name.invalid")),
    description: z.string().max(200, t("form.validations.description.invalid")),
    slug: z
      .string()
      .min(3, t("form.validations.slug.minCharacter"))
      .max(12, t("form.validations.slug.maxCharacter"))
      .regex(/^[^-~!@#$%^&*()+].*[^-~!@#$%^&*()+]$/, {
        message: t("form.validations.slug.noSpecialCharacter"),
      }),
  });

  const handleCancel = () => {
    navigate(ROUTES.HOME);
  };

  const handleSubmit = async (data: Workspace) => {
    try {
      let response: WorkspaceResponse | ErrorResponse;

      if (workspace) {
        response = (await triggerUpdate({ data, id: workspace.id })) as
          | WorkspaceResponse
          | ErrorResponse;
      } else {
        response = (await triggerAdd(data)) as
          | WorkspaceResponse
          | ErrorResponse;
      }

      if ("data" in response) {
        const successMessage = workspace
          ? t("form.messages.success.update")
          : t("form.messages.success.create");

        toast.success(successMessage);

        if (!workspace) {
          navigate(`/workspaces/${response.data.slug}/releases`);
        }
      } else if ("error" in response) {
        handleErrorResponse(response.error);
      }
    } catch {
      toast.error(t("form.messages.error.default"));
    }
  };

  const handleErrorResponse = (error: ErrorResponse["error"]) => {
    if (error.status === 409) {
      const errorMessage = error.data.error;
      const errorSlugMessages: Record<string, string> = {
        ERROR_SLUG_ALREADY_EXISTS: t("form.validations.slug.alreadyExist"),
        ERROR_GITLAB_WORKSPACE_ALREADY_EXISTS: t(
          "form.validations.gitlab.workspace.alreadyExist",
        ),
      };

      setFieldErrors({ slug: errorSlugMessages[errorMessage] });
    } else {
      toast.error(t("form.messages.error.default"));
    }
  };

  const defaultValues = {
    name: workspace?.name || "",
    description: workspace?.description || "",
    slug: workspace?.slug || "",
  };

  return (
    <Provider
      onSubmit={handleSubmit}
      validationSchema={workspaceValidationSchema}
      className="workspace-form"
      values={defaultValues}
    >
      <WorkspaceFormFields
        handleCancel={handleCancel}
        isLoading={isLoadingAdd}
        workspace={workspace}
        fieldErrors={fieldErrors}
      />
    </Provider>
  );
};

export default WorkspaceForm;
