import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Card, CardBody, CardHeader, Page } from "@dzangolab/react-ui";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../constants";
import { useLazyGetWorkspacesQuery } from "../../redux/apis/workspaces";
import { resetSelectedWorkspaces } from "../../redux/SelectedWorkspacesSlice";

import type { Workspace } from "core";

const Workspaces = () => {
  const [fetchWorkspaces, { data: workspaces }] = useLazyGetWorkspacesQuery();
  const { t } = useTranslation("workspaces");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDataAndNavigate = async () => {
      try {
        const response = await fetchWorkspaces();

        if (!response.data || response.data?.length === 0) {
          navigate(ROUTES.WORKSPACE_CREATE);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Error fetching workspaces:", err);
      }
    };

    // Call the async function
    fetchDataAndNavigate();
  }, [fetchWorkspaces, navigate]);

  return (
    <Page
      className="workspaces-page"
      title={t("title")}
      toolbar={
        <Button onClick={() => navigate(ROUTES.WORKSPACE_CREATE)}>
          <i className="pi pi-plus"></i>
        </Button>
      }
    >
      {workspaces &&
        workspaces.map((workspace: Workspace) => (
          <div
            onClick={() => {
              dispatch(resetSelectedWorkspaces());
              navigate(`/workspaces/${workspace.secondaryIdentifier}/releases`);
            }}
            key={workspace.id}
            className="card-wrapper"
          >
            <Card outlined>
              <CardHeader>{<h2>{workspace.name}</h2>}</CardHeader>

              <CardBody>
                {workspace.description ? <p>{workspace.description}</p> : ""}
              </CardBody>
            </Card>
          </div>
        ))}
    </Page>
  );
};

export default Workspaces;
