import { useTranslation } from "@dzangolab/react-i18n";
import {
  Button,
  TDataTable as DataTable,
  Page,
  TableColumnDefinition,
} from "@dzangolab/react-ui";
import { SERVICE_STATUS, SERVICE_TYPES, Service, getKeyFromValue } from "core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import ServiceFormModal from "./components/ServiceFormModal";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import {
  useLazyDeployServiceQuery,
  useLazyGetServicesQuery,
  useLazyStopServiceQuery,
} from "../../redux/apis/services";

const Services = () => {
  const { t } = useTranslation("services");

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedService, setSelectedService] = useState<Service>();

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedService(undefined);
  };

  const [fetchServices, { data: workspaceServices, isFetching: isLoading }] =
    useLazyGetServicesQuery();

  const [stopService, { isFetching: isStoppingService }] =
    useLazyStopServiceQuery();

  const [stoppingServiceIds, setStoppingServiceIds] = useState<number[]>([]);
  const [startingServiceIds, setStartingServiceIds] = useState<number[]>([]);

  const stopActionHandler = async (service: Service) => {
    setStoppingServiceIds([...stoppingServiceIds, service.id]);

    const response = await stopService({
      id: service.id,
      workspaceId: service.workspaceId,
    });

    if (response.error) {
      toast.error(t("messages.error.stop"));

      return;
    }

    fetchServices({ workspaceId: currentWorkspace.id });

    setStoppingServiceIds((ids) => ids.filter((id) => id !== service.id));
  };

  const editActionHandler = (service: Service) => {
    setSelectedService(service);
    openModal();
  };

  useEffect(() => {
    if (currentWorkspace) {
      fetchServices({ workspaceId: currentWorkspace.id });
    }
  }, [currentWorkspace, fetchServices]);

  const [deployService, { isFetching: isDeployingService }] =
    useLazyDeployServiceQuery();

  const buildActionHandler = async (selectedService: Service) => {
    setStartingServiceIds([...startingServiceIds, selectedService.id]);

    const response = await deployService({
      id: selectedService.id,
      workspaceId: selectedService.workspaceId,
    });

    if (response.error) {
      toast.error(t("messages.error.start"));

      return;
    }

    setStartingServiceIds((ids) =>
      ids.filter((id) => id !== selectedService.id),
    );

    fetchServices({ workspaceId: currentWorkspace.id });
  };

  const columns: Array<TableColumnDefinition<any>> = [
    {
      accessorKey: "name",
      header: t("table.columns.name"),
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => (
        <>
          <span>{original.name}</span>
          {original.isPublic &&
            original.statusId === 2 &&
            original.publicUrl && (
              <a
                className="service-name-link"
                href={original.publicUrl}
                target="_blank"
                rel="noreferrer"
              >
                <i className="pi pi-external-link"></i>
              </a>
            )}
        </>
      ),
    },
    {
      accessorKey: "typeId",
      header: t("table.columns.type"),
      width: "10rem",
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        const rowAType =
          getKeyFromValue(SERVICE_TYPES, rowA.original.typeId) || "";
        const rowBType =
          getKeyFromValue(SERVICE_TYPES, rowB.original.typeId) || "";

        return rowAType.localeCompare(rowBType);
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return getKeyFromValue(SERVICE_TYPES, original.typeId) || "";
      },
    },
    {
      accessorKey: "statusId",
      accessorFn: (row) => row.statusId ?? undefined,
      header: t("table.columns.status"),
      width: "10rem",
      enableSorting: true,
      sortUndefined: "last",
      sortingFn: (rowA, rowB) => {
        const rowAStatus =
          getKeyFromValue(SERVICE_STATUS, rowA.original.statusId) || "";
        const rowBStatus =
          getKeyFromValue(SERVICE_STATUS, rowB.original.statusId) || "";

        return rowAStatus.localeCompare(rowBStatus);
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return getKeyFromValue(SERVICE_STATUS, original.statusId) || "";
      },
    },
  ];

  const renderToolbar = () => {
    return (
      <Button onClick={openModal}>
        <i className="pi pi-plus"></i>
      </Button>
    );
  };

  return (
    <Page title={t("title")} loading={isLoading} toolbar={renderToolbar()}>
      {workspaceServices && (
        <DataTable
          columns={columns}
          className="workspace-services-table"
          isLoading={isLoading}
          data={workspaceServices}
          emptyTableMessage={t("table.empty")}
          initialSorting={[
            {
              id: "name",
              desc: false,
            },
          ]}
          dataActionsMenu={(data) => ({
            actions: [
              {
                label: t("table.actions.edit"),
                onClick: (rowData) => editActionHandler(rowData),
              },
              {
                label: t("table.actions.start"),
                confirmationOptions: {
                  header: t("confirmation.start.header"),
                  message: t("confirmation.start.message", {
                    service: data.name,
                  }),
                },
                display: (rowData) =>
                  !(
                    rowData.statusId === SERVICE_STATUS.RUNNING ||
                    rowData.statusId === SERVICE_STATUS.STARTING
                  ),
                disabled: (rowData) =>
                  rowData.statusId === SERVICE_STATUS.RUNNING ||
                  (startingServiceIds.includes(rowData.id) &&
                    isDeployingService),
                onClick: (rowData: any) => {
                  buildActionHandler(rowData);
                },
                requireConfirmationModal: true,
              },
              {
                className: "table-action-danger",
                confirmationOptions: {
                  header: t("confirmation.stop.header"),
                  message: t("confirmation.stop.message", {
                    service: data.name,
                  }),
                },
                label: t("table.actions.stop"),
                onClick: (rowData: any) => stopActionHandler(rowData),
                display: (rowData) =>
                  !(
                    rowData.statusId === SERVICE_STATUS.STOPPED ||
                    rowData.statusId === SERVICE_STATUS.STOPPING
                  ),
                disabled: (rowData) =>
                  rowData.statusId === SERVICE_STATUS.STOPPED ||
                  rowData.statusId !== SERVICE_STATUS.RUNNING ||
                  (stoppingServiceIds.includes(rowData.id) &&
                    isStoppingService),
                requireConfirmationModal: true,
              },
            ],
          })}
        />
      )}

      <ServiceFormModal
        visible={isModalVisible}
        onHide={closeModal}
        service={selectedService}
      />
    </Page>
  );
};

export default Services;
