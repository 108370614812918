import { useTranslation } from "@dzangolab/react-i18n";
import { Modal } from "@dzangolab/react-ui";
import { BranchMatchingRule, ReleaseType } from "core";
import { useState } from "react";

import BranchRuleForm from "../BranchRuleForm";

interface Properties {
  onHide: () => void;
  releaseTypes: ReleaseType[];
  ruleTypes: { id: number; name: string }[];
  visible: boolean;
  branchRule?: BranchMatchingRule;
  repositoryId: number;
  workspaceId: number;
}

const BranchRuleFormModal: React.FC<Properties> = ({
  onHide,
  releaseTypes,
  repositoryId,
  ruleTypes,
  workspaceId,
  branchRule,
  visible,
}) => {
  const { t } = useTranslation("repositoryDetails");

  const [fieldErrors] = useState<Record<string, string>>();

  return (
    <Modal
      className="branch-rule-form-modal"
      header={branchRule ? t("modal.header.edit") : t("modal.header.new")}
      onHide={onHide}
      visible={visible}
    >
      <BranchRuleForm
        fieldErrors={fieldErrors}
        onHide={onHide}
        releaseTypes={releaseTypes}
        ruleTypes={ruleTypes}
        branchRule={branchRule}
        repositoryId={repositoryId}
        workspaceId={workspaceId}
      />
    </Modal>
  );
};

export default BranchRuleFormModal;
