import { useTranslation } from "@dzangolab/react-i18n";
import { Page } from "@dzangolab/react-ui";
import { useEffect } from "react";

import { ReleaseTypesTable } from "./components";
import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";
import { useLazyGetReleaseTypesQuery } from "../../../redux/apis/release";

const DocumentationSettings = () => {
  const { t } = useTranslation("releaseTypeForm");
  const [
    getReleaseTypes,
    { data: releaseTypes, isFetching: isFetchingReleaseTypes },
  ] = useLazyGetReleaseTypesQuery();

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  useEffect(() => {
    if (currentWorkspace) {
      getReleaseTypes({
        workspaceId: currentWorkspace.id,
      });
    }
  }, [currentWorkspace, getReleaseTypes]);

  return (
    <Page
      className="release-settings-page"
      title={t("release-settings.title")}
      loading={isFetchingReleaseTypes}
    >
      {currentWorkspace && releaseTypes && (
        <ReleaseTypesTable
          loading={isFetchingReleaseTypes}
          releaseTypes={releaseTypes}
          workspace={currentWorkspace}
        />
      )}
    </Page>
  );
};

export default DocumentationSettings;
