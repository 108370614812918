import app from "./app.json";
import appOverview from "./appOverview.json";
import auth from "./auth.json";
import branchPicker from "./branchPicker.json";
import docs from "./docs.json";
import errors from "./errors.json";
import glossary from "./glossary.json";
import invitations from "./invitations.json";
import locales from "./locales.json";
import markdownEditor from "./markdownEditor.json";
import release from "./release.json";
import releaseTypeForm from "./releaseTypeForm.json";
import repositories from "./repositories.json";
import repositoryDetails from "./repositoryDetails.json";
import repositoryPicker from "./repositoryPicker.json";
import services from "./services.json";
import settings from "./settings.json";
import user from "./user.json";
import workspaceDataModel from "./workspaceDataModel.json";
import workspaceDelete from "./workspaceDelete.json";
import workspaceForm from "./workspaceForm.json";
import workspaces from "./workspaces.json";
import workspaceTeam from "./workspaceTeam.json";

const en = {
  app,
  appOverview,
  auth,
  branchPicker,
  docs,
  errors,
  glossary,
  invitations,
  locales,
  markdownEditor,
  release,
  releaseTypeForm,
  repositories,
  repositoryDetails,
  repositoryPicker,
  services,
  settings,
  user,
  workspaceDataModel,
  workspaceDelete,
  workspaceForm,
  workspaceTeam,
  workspaces,
};

export default en;
