import { useTranslation } from "@dzangolab/react-i18n";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";

interface Properties {
  branches: string[];
  errorMessage?: string;
  onSelect: (branch: string) => void;
  value?: string;
}

const BranchPicker: React.FC<Properties> = (properties) => {
  const { branches, errorMessage, value, onSelect } = properties;

  const { t } = useTranslation("branchPicker");

  const handleChange = (event: DropdownChangeEvent) => {
    onSelect(event.value);
  };

  return (
    <div className="branch-picker-wrapper">
      <Dropdown
        className="branch-picker"
        emptyFilterMessage={t("dropdown.emptyMessage")}
        emptyMessage={t("dropdown.emptyMessage")}
        filter
        onChange={handleChange}
        options={branches}
        placeholder={t("dropdown.placeholder")}
        value={value}
      />
      {errorMessage && <span className="error-message">{errorMessage}</span>}
    </div>
  );
};

export default BranchPicker;
