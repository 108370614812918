import React, { createContext, useContext } from "react";

import { useWebSocket } from "./hooks/UseWebSocket";

interface WebSocketContextType {
  message: string | null;
}

interface WebSocketProviderProperties {
  children: React.ReactNode;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined,
);

export const WebSocketProvider: React.FC<WebSocketProviderProperties> = ({
  children,
}) => {
  const webSocket = useWebSocket();

  return (
    <WebSocketContext.Provider value={webSocket}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);

  if (context === undefined) {
    throw new Error(
      "useWebSocketContext must be used within a WebSocketProvider",
    );
  }

  return context;
};
