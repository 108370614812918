import { Button } from "@dzangolab/react-ui";
import { CmdKey, Editor, editorViewCtx } from "@milkdown/core";
import { Ctx } from "@milkdown/ctx";
import {
  insertImageCommand,
  linkSchema,
  toggleEmphasisCommand,
  toggleLinkCommand,
  toggleStrongCommand,
  turnIntoTextCommand,
  updateLinkCommand,
  wrapInBlockquoteCommand,
  wrapInBulletListCommand,
  wrapInHeadingCommand,
  wrapInOrderedListCommand,
} from "@milkdown/preset-commonmark";
import {
  insertTableCommand,
  toggleStrikethroughCommand,
} from "@milkdown/preset-gfm";
import { callCommand } from "@milkdown/utils";
import { OverlayPanel } from "primereact/overlaypanel";
import React, { useRef, useState } from "react";

import LinkToolbar from "./LinkTooltip/LinkToolbar";
import TableTooltip from "./TableTooltip";
import "./toolbar.css";
import { getMarkdown } from "./utils/GetMarkdown";
interface ToolbarProperties {
  get: () => Editor | undefined;
  onSave?: (content: string) => void;
}

export default function MilkdownToolbar({ get, onSave }: ToolbarProperties) {
  const editor = get();

  const tableOverlayReference = useRef<OverlayPanel>(null);
  const linkOverlayReference = useRef<OverlayPanel>(null);
  const imgOverlayReference = useRef<OverlayPanel>(null);

  const [link, setLink] = useState({
    title: "",
    src: "",
  });

  function getLinkAttributes(context: Ctx, pos: number) {
    const view = context.get(editorViewCtx);
    const node = view.state.doc.nodeAt(pos);

    if (!node) return null;

    const linkMark = node.marks.find(
      (mark) => mark.type === linkSchema.mark.type(context),
    );

    if (!linkMark) return null;

    const { href, title } = linkMark.attrs;

    return { href, title };
  }

  const insertLink = (
    context: Ctx,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const view = context.get(editorViewCtx);
    const { selection, doc } = view.state;

    if (selection.empty) return;

    const hasLink = doc.rangeHasMark(
      selection.from,
      selection.to,
      linkSchema.type(context),
    );

    if (hasLink) {
      const linkAttributes = getLinkAttributes(context, selection.from);

      if (linkAttributes) {
        setLink({
          title: linkAttributes.title,
          src: linkAttributes.href,
        });
      }
    }
    if (!hasLink) {
      setLink({
        title: "",
        src: "",
      });
    }

    linkOverlayReference.current?.toggle(event);
  };

  function call<T>(command: CmdKey<T>, payload?: T) {
    return get()?.action(callCommand(command, payload));
  }

  return (
    <div className="toolbar-wrapper">
      <Button
        label="B"
        onMouseDown={(event) => {
          call(toggleStrongCommand.key);
          event.preventDefault();
        }}
        type="button"
        size="small"
        variant="textOnly"
        className="toolbar-button"
      />

      <Button
        label="I"
        onMouseDown={(event) => {
          call(toggleEmphasisCommand.key);
          event.preventDefault();
        }}
        size="small"
        type="button"
        variant="textOnly"
        className="toolbar-button"
      />

      <Button
        onMouseDown={(event) => {
          call(toggleStrikethroughCommand.key);
          event.preventDefault();
        }}
        iconLeft="pi pi-minus"
        size="small"
        type="button"
        variant="textOnly"
        className="toolbar-button"
      />

      <Button
        iconLeft="pi pi-link"
        onClick={(event) => {
          editor?.action((Context) => {
            insertLink(Context, event);
          });
        }}
        size="small"
        type="button"
        variant="textOnly"
        className="toolbar-button"
      />

      <OverlayPanel ref={linkOverlayReference} showCloseIcon>
        <LinkToolbar
          onSave={(title, href, event) => {
            if (link.src) {
              call(updateLinkCommand.key, { href: href, title: title });
            }
            call(toggleLinkCommand.key, { href: href, title: title });
            linkOverlayReference.current?.toggle(event);
          }}
          defaultValues={link}
        />
      </OverlayPanel>

      <Button
        label="'' ''"
        onMouseDown={(event) => {
          call(wrapInBlockquoteCommand.key);
          event.preventDefault();
        }}
        size="small"
        type="button"
        variant="textOnly"
        className="toolbar-button"
      />

      <Button
        label="H1"
        onMouseDown={(event) => {
          call(wrapInHeadingCommand.key, 1);
          event.preventDefault();
        }}
        size="small"
        type="button"
        variant="textOnly"
        className="toolbar-button"
      />

      <Button
        label="H2"
        onMouseDown={(event) => {
          call(wrapInHeadingCommand.key, 2);
          event.preventDefault();
        }}
        size="small"
        type="button"
        variant="textOnly"
        className="toolbar-button"
      />

      <Button
        label="H3"
        onMouseDown={(event) => {
          call(wrapInHeadingCommand.key, 3);
          event.preventDefault();
        }}
        size="small"
        type="button"
        variant="textOnly"
        className="toolbar-button"
      />

      <Button
        label="OL"
        onMouseDown={(event) => {
          call(wrapInOrderedListCommand.key);
          event.preventDefault();
        }}
        size="small"
        type="button"
        variant="textOnly"
        className="toolbar-button"
      />

      <Button
        iconLeft="pi pi-list"
        onMouseDown={(event) => {
          call(wrapInBulletListCommand.key);
          event.preventDefault();
        }}
        size="small"
        type="button"
        variant="textOnly"
        className="toolbar-button"
      />

      <Button
        label="Text"
        onMouseDown={(event) => {
          call(turnIntoTextCommand.key);
          event.preventDefault();
        }}
        size="small"
        type="button"
        variant="textOnly"
        className="toolbar-button"
      />

      <Button
        iconLeft="pi pi-image"
        onClick={(event) => {
          imgOverlayReference.current?.toggle(event);
        }}
        size="small"
        type="button"
        variant="textOnly"
        className="toolbar-button"
      />

      <OverlayPanel ref={imgOverlayReference} showCloseIcon>
        <LinkToolbar
          onSave={(title, href, event) => {
            call(insertImageCommand.key, {
              src: href,
              title: title,
              alt: title,
            });
            imgOverlayReference.current?.toggle(event);
          }}
        />
      </OverlayPanel>

      <Button
        iconLeft="pi pi-table"
        onClick={(event) => {
          tableOverlayReference.current?.toggle(event);
        }}
        size="small"
        type="button"
        variant="textOnly"
        className="toolbar-button"
      />

      <OverlayPanel ref={tableOverlayReference} showCloseIcon>
        <TableTooltip
          onSave={(rows: number, column: number, event) => {
            call(insertTableCommand.key, { row: rows, col: column });
            tableOverlayReference.current?.toggle(event);
          }}
        />
      </OverlayPanel>

      {onSave && (
        <Button
          iconLeft="pi pi-save"
          onClick={() => {
            const markdownContent = getMarkdown(get);

            onSave(markdownContent);
          }}
          size="small"
          type="button"
          variant="textOnly"
          className="toolbar-button"
        />
      )}
    </div>
  );
}
