import { createSlice } from "@reduxjs/toolkit";

interface SelectedWorkspaces {
  workspaces: {
    [key: string]: {
      workspace: any;
      selectedRelease: any;
    };
  };
}

const initialState: SelectedWorkspaces = {
  workspaces: {},
};

export const selectedWorkspacesSlice = createSlice({
  name: "selectedWorkspaces",
  initialState,
  reducers: {
    setSelectedWorkspace: (state, action) => {
      let { workspace, release } = action.payload;

      let workspaces = JSON.parse(localStorage.getItem("workspaces") || "{}");

      if (
        !release &&
        workspaces[workspace.secondaryIdentifier] &&
        workspaces[workspace.secondaryIdentifier].selectedRelease
      ) {
        release = workspaces[workspace.secondaryIdentifier].selectedRelease;
      }

      workspaces[workspace.secondaryIdentifier] = {
        workspace,
        selectedRelease: release,
      };

      localStorage.setItem("workspaces", JSON.stringify(workspaces));

      state.workspaces = workspaces;
    },

    setWorkspaceAndRelease: (state, action) => {
      const { workspaceSecondaryId, workspace, release } = action.payload;

      let workspaces = JSON.parse(localStorage.getItem("workspaces") || "{}");

      workspaces[workspaceSecondaryId].workspace = workspace;
      workspaces[workspaceSecondaryId].selectedRelease = release;
      localStorage.setItem("workspaces", JSON.stringify(workspaces));

      state.workspaces = workspaces;
    },

    resetSelectedWorkspaces: (state) => {
      state.workspaces = {};
      localStorage.removeItem("workspaces");
    },

    resetSelectedRelease: (state, action) => {
      const { workspace } = action.payload;
      let workspaces = JSON.parse(localStorage.getItem("workspaces") || "{}");

      const { selectedRelease, ...restWorkspaceData } =
        workspaces[workspace.secondaryIdentifier];

      workspaces[workspace.secondaryIdentifier] = {
        ...restWorkspaceData,
      };

      localStorage.setItem("workspaces", JSON.stringify(workspaces));

      state.workspaces = workspaces;
    },
  },
});

export const {
  setSelectedWorkspace,
  setWorkspaceAndRelease,
  resetSelectedWorkspaces,
  resetSelectedRelease,
} = selectedWorkspacesSlice.actions;

export default selectedWorkspacesSlice.reducer;
