import { Select, TextInput, useFormContext } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { SubmitButton } from "@dzangolab/react-ui";
import { DocumentType } from "core";
import { useEffect } from "react";

interface Properties {
  documentTypes: DocumentType[];
  fieldErrors?: { [key: string]: string };
  submittingForm?: boolean;
  documentTypeToCreate: "file" | "folder";
}

const AddDocumentFormFields: React.FC<Properties> = (properties) => {
  const { documentTypes, submittingForm, fieldErrors, documentTypeToCreate } =
    properties;
  const { t } = useTranslation("docs");
  const {
    formState: { errors },
    setError,
  } = useFormContext();

  useEffect(() => {
    setError("name", {
      type: "manual",
      message: fieldErrors?.name as string,
    });
  }, [fieldErrors, setError]);

  if (documentTypeToCreate === "folder") {
    return (
      <>
        <TextInput
          label={t("add-docs.form.folder-name.label")}
          name={`name`}
          placeholder={t("add-docs.form.folder-name.placeholder")}
          autoFocus
        />
        <SubmitButton
          label={t("add-docs.actions.submit")}
          loading={submittingForm}
          disabled={Object.keys(errors).length > 0}
        />
      </>
    );
  }

  return (
    <>
      <TextInput
        label={t("add-docs.form.name.label")}
        name={`name`}
        placeholder={t("add-docs.form.name.placeholder")}
        autoFocus
      />
      <Select
        name="typeId"
        label={t("add-docs.form.type.label")}
        placeholder={t("add-docs.form.type.placeholder")}
        options={documentTypes.map((template) => ({
          label: template.name,
          value: template.id,
        }))}
      />

      <SubmitButton
        label={t("add-docs.actions.submit")}
        loading={submittingForm}
        disabled={Object.keys(errors).length > 0}
      />
    </>
  );
};

export default AddDocumentFormFields;
