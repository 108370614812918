import {
  Textarea,
  TextInput,
  useFieldArray,
  useFormContext,
  useWatch,
} from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button, SubmitButton, Tag } from "@dzangolab/react-ui";
import { GlossaryEntry, GlossaryLocales, SupportedLocales } from "core";
import { useNavigate } from "react-router-dom";

import { useCurrentWorkspace } from "../../../../hooks/UseCurrentWorkspace";

interface Properties {
  entry?: GlossaryEntry;
  glossaryId: number;
  glossaryLocales: GlossaryLocales[];
  isSubmitting?: boolean;
  mainLocale: GlossaryLocales;
  supportedLocales: SupportedLocales[];
  onSubmit: (data: any) => Promise<void>;
}

const GlossaryEntryFormFields: React.FC<Properties> = (properties) => {
  const { isSubmitting, entry, mainLocale, onSubmit } = properties;

  const { t } = useTranslation("glossary");
  const navigate = useNavigate();

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const { fields } = useFieldArray({
    name: "glossaryEntryI18n",
  });

  const fieldRows = useWatch({ name: "glossaryEntryI18n" });

  const {
    handleSubmit,
    trigger,
    reset,
    setValue,
    formState: { errors },
  } = useFormContext();

  const getErrorMessage = (index: number, field: string): string => {
    return (errors as any)?.glossaryEntryI18n?.[index]?.[field]?.message || "";
  };

  const onSaveAndAddAnother = async () => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmit(onSubmit)();

      reset();
    }
  };

  return (
    <>
      {fields.map((field, index) => {
        const fieldName = `glossaryEntryI18n.${index}`;

        return (
          <fieldset name={fieldName} key={field.id}>
            <div className="field-actions">
              <Tag key={field.id} label={fieldRows[index].locale} />
              {fieldRows[index]?.locale !== mainLocale.localeCode && entry && (
                <Button
                  variant={"textOnly"}
                  iconLeft={"pi pi-trash"}
                  type={"button"}
                  severity={"danger"}
                  onClick={() => {
                    setValue(`${fieldName}.term`, "");
                    setValue(`${fieldName}.definition`, "");
                  }}
                />
              )}
            </div>
            <div className="glossary-form-fields">
              <TextInput
                label={"Term"}
                name={`${fieldName}.term`}
                placeholder={""}
                errorMessage={getErrorMessage(index, "term")}
              />
              <Textarea
                label={"Definition"}
                name={`${fieldName}.definition`}
                placeholder=""
                rows={5}
              />
            </div>
          </fieldset>
        );
      })}
      <div className="glossary-actions-wrapper">
        <div className="glossary-action-buttons">
          <Button
            label={t("glossary-entry.form.button.cancel")}
            severity="secondary"
            type="button"
            variant="outlined"
            onClick={() =>
              navigate(
                `/workspaces/${currentWorkspace.secondaryIdentifier}/glossary`,
              )
            }
          />
          {fields.length > 0 && (
            <>
              {!entry && (
                <Button
                  type="button"
                  label={t("glossary-entry.form.button.saveAndAddAnother")}
                  onClick={onSaveAndAddAnother}
                />
              )}
              <SubmitButton
                label={t("glossary-entry.form.button.save")}
                loading={isSubmitting}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GlossaryEntryFormFields;
