import {
  BranchMatchingRule,
  BranchMatchingRuleCreateInput,
  BranchMatchingRuleUpdateInput,
} from "core";

import baseApi, { TagsEnum } from "./base-api";

const branchRulesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRuleTypes: build.query<any, any>({
      query: () => {
        return {
          url: `/branch-rules/rule-types`,
        };
      },
    }),

    createBranchRule: build.mutation<
      BranchMatchingRule,
      { input: BranchMatchingRuleCreateInput; workspaceId: number }
    >({
      query: ({ input, workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/repositories/${input.repositoryId}/branch-rules`,
          method: "POST",
          body: input,
        };
      },
      invalidatesTags: [TagsEnum.BranchRules],
    }),

    updateBranchRule: build.mutation<
      BranchMatchingRule,
      { id: number; input: BranchMatchingRuleUpdateInput; workspaceId: number }
    >({
      query: ({ id, input, workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/repositories/${input.repositoryId}/branch-rules/${id}`,
          method: "PUT",
          body: input,
        };
      },
      invalidatesTags: [TagsEnum.BranchRules],
    }),
  }),
});

export const {
  useLazyGetRuleTypesQuery,
  useUpdateBranchRuleMutation,
  useCreateBranchRuleMutation,
} = branchRulesApi;

export default branchRulesApi;
