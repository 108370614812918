import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { ConfirmationModal, Modal } from "@dzangolab/react-ui";
import { sanitizeVolumeMounts, Service } from "core";
import { useState } from "react";
import { toast } from "react-toastify";
import { z } from "zod";

import ServiceFormFields from "./ServiceFormFields";
import { useCurrentWorkspace } from "../../../../hooks/UseCurrentWorkspace";
import {
  useCreateServiceMutation,
  useDeleteServiceMutation,
  useLazyGetServicesQuery,
  useUpdateServiceMutation,
} from "../../../../redux/apis/services";

interface Properties {
  onHide: () => void;
  service?: Service;
  visible: boolean;
}

interface ErrorResponse {
  error: {
    status: number;
    data: {
      error: string;
      message: string;
    };
  };
}

interface SuccessResponse {
  data: Service;
}

const ServiceFormModal: React.FC<Properties> = ({
  onHide,
  service,
  visible,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { t } = useTranslation("services");
  const { workspace } = useCurrentWorkspace();

  const [fetchServices] = useLazyGetServicesQuery();
  const [triggerAdd, { isLoading: isCreating }] = useCreateServiceMutation();
  const [triggerUpdate, { isLoading: isUpdating }] = useUpdateServiceMutation();
  const [deleteService, { isLoading: isDeleting }] = useDeleteServiceMutation();

  const registrySchema = z.object({
    provider: z.string().optional(),
    url: z.string().optional(),
    authentication: z
      .object({
        username: z.string().optional(),
        password: z.string().optional(),
        awsAccessKeyId: z.string().optional(),
        awsSecretAccessKey: z.string().optional(),
        awsRegion: z.string().optional(),
      })
      .optional(),
  });

  const validationSchema = z.object({
    connectionConfigs: z
      .string()
      .refine((value) => {
        try {
          if (value) {
            JSON.parse(value);
          }

          return true;
        } catch (_) {
          return false;
        }
      }, t("form.validations.connectionConfigs.invalid"))
      .transform((value) => (value ? JSON.parse(value) : null))
      .nullable(),
    envVars: z
      .object({
        name: z.string().min(1, t("form.validations.name.required")),
        value: z.union([z.string(), z.number(), z.boolean()]),
      })
      .array(),
    image: z.string().nullable(),
    imageVersion: z.string().nullable(),
    isExternal: z.boolean(),
    isPublic: z.boolean().nullable(),
    name: z.string().min(1, t("form.validations.name.required")),
    port: z.number().nullable(),
    publicUrl: z.string().nullable(),
    scripts: z.string().nullable(),
    slug: z.string().min(1, t("form.validations.slug.required")),
    statusId: z.number().nullable(),
    typeId: z.number({
      invalid_type_error: t("form.validations.typeId.required"),
      required_error: t("form.validations.typeId.required"),
    }),
    volumeMounts: z.string().nullable(),
    registry: registrySchema.optional().nullable(),
  });

  const defaultValues = {
    connectionConfigs: "",
    envVars: [],
    image: "",
    imageVersion: "",
    isExternal: false,
    isPublic: false,
    name: "",
    port: null,
    publicUrl: null,
    scripts: "",
    slug: "",
    statusId: null,
    typeId: null,
    volumeMounts: "",
    registry: {
      provider: "",
      url: "",
      authentication: {
        username: "",
        password: "",
        awsAccessKeyId: "",
        awsSecretAccessKey: "",
        awsRegion: "",
      },
    },
  };

  const getInitialValues = () => {
    if (!service) return defaultValues;

    const connectionConfigs = service.connectionConfigs
      ? JSON.stringify(service.connectionConfigs)
      : "";

    const envVariables = service.envVars
      ? Object.keys(service.envVars).map((key) => ({
          name: key,
          value: service.envVars[key],
        }))
      : [];

    return {
      connectionConfigs,
      envVars: envVariables,
      image: service.image || "",
      imageVersion: service.imageVersion || "",
      isExternal: service.isExternal || false,
      isPublic: service.isPublic || false,
      name: service.name,
      port: service.port,
      publicUrl: service.publicUrl,
      scripts: service.scripts ? service.scripts.join(",") : "",
      slug: service.slug,
      statusId: service.statusId,
      typeId: service.typeId,
      volumeMounts: service.volumeMounts ? service.volumeMounts.join(",") : "",
      registry:
        "registry" in service
          ? service.registry
          : {
              provider: "",
              url: "",
              authentication: {
                username: "",
                password: "",
                awsAccessKeyId: "",
                awsSecretAccessKey: "",
                awsRegion: "",
              },
            },
    };
  };

  const handleSubmit = async (data: z.infer<typeof validationSchema>) => {
    if (!workspace) return;

    let response: SuccessResponse | ErrorResponse;

    try {
      const envVariables = data.envVars.reduce(
        (result: Record<string, string>, { name, value }) => {
          result[name] = String(value);

          return result;
        },
        {},
      );

      const scripts = data.scripts ? data.scripts.split(",") : null;
      const volumeMounts = data.volumeMounts
        ? sanitizeVolumeMounts(data.volumeMounts).split(",")
        : null;

      if (!service) {
        response = (await triggerAdd({
          ...data,
          envVars: envVariables,
          image: data.image || null,
          imageVersion: data.imageVersion || null,
          scripts,
          volumeMounts,
          workspaceId: workspace.id,
        })) as SuccessResponse | ErrorResponse;
      } else {
        response = (await triggerUpdate({
          id: service.id,
          workspaceId: workspace.id,
          data: {
            ...data,
            envVars: envVariables,
            image: data.image || null,
            imageVersion: data.imageVersion || null,
            scripts,
            volumeMounts,
          },
        })) as SuccessResponse | ErrorResponse;
      }

      if ("data" in response) {
        if (!service) {
          const successMessage = t("messages.success.create");

          toast.success(successMessage);
        }

        onHide();
      } else if ("error" in response) {
        const errorMessage = service
          ? t("messages.error.update")
          : t("messages.error.create");

        toast.error(errorMessage);
      }
    } catch {
      toast.error(t("messages.error.default"));
    }
  };

  const handleServiceDelete = async () => {
    if (!workspace || !service) return;

    try {
      const response = await deleteService({
        id: service.id,
        workspaceId: workspace.id,
      });

      if ("error" in response) {
        const error = (response?.error as any)?.data.error;

        if (error === "SERVICE_ASSOCIATED_WITH_RUNNING_APPS") {
          toast.error(t("messages.error.delete.runningApps"));

          return;
        }

        if (error === "SERVICE_NOT_STOPPED") {
          onHide();
          toast.error(t("messages.error.delete.notStopped"));

          return;
        }

        toast.error(t("messages.error.delete.default"));
      }

      onHide();
    } catch {
      toast.error(t("messages.error.delete.default"));
    }
  };

  return (
    <Modal
      onHide={onHide}
      visible={visible}
      header={service ? t("modal.header.edit") : t("modal.header.new")}
      className="service-form-modal"
    >
      <Provider
        className="service-form"
        defaultValues={getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <ServiceFormFields
          handleCancel={onHide}
          isLoading={isCreating || isUpdating}
          service={service}
          onDelete={() => setShowDeleteModal(true)}
        />
      </Provider>
      <ConfirmationModal
        accept={handleServiceDelete}
        acceptButtonOptions={{
          label: t("delete.confirmation.actions.delete", {
            service: service?.name,
          }),
          severity: "danger",
          variant: "outlined",
        }}
        cancelButtonOptions={{
          label: t("delete.confirmation.actions.cancel"),
        }}
        header={t("delete.confirmation.title", {
          service: service?.name,
        })}
        message={t("delete.confirmation.message", {
          service: service?.name,
        })}
        visible={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      />
    </Modal>
  );
};

export default ServiceFormModal;
