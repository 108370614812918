import { useTranslation } from "@dzangolab/react-i18n";
import { Button, ConfirmationModal, Message, Page } from "@dzangolab/react-ui";
import { Repository } from "core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { RepositoryCard } from "./components";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import {
  useDeleteRepositoryMutation,
  useLazyGetRepositoriesQuery,
} from "../../redux/apis/repositories";

interface SuccessResponse {
  data: {
    id: number;
  };
}

interface ErrorResponse {
  error: {
    data: {
      error: string;
    };
  };
}

const Repositories = () => {
  const { t } = useTranslation("repositories");
  const navigate = useNavigate();

  const { workspace } = useCurrentWorkspace();
  const [repositoryToDelete, setRepositoryToDelete] =
    useState<Repository | null>(null);

  const [fetchRepositories, { data, isFetching }] =
    useLazyGetRepositoriesQuery();
  const [deleteRepository, { isLoading }] = useDeleteRepositoryMutation();

  const handleDelete = (repository: Repository) => {
    setRepositoryToDelete(repository);
  };

  const deleteHandler = async () => {
    if (!repositoryToDelete) return;

    const { id, workspaceId } = repositoryToDelete;

    let response = (await deleteRepository({
      workspaceId,
      repositoryId: id,
    })) as SuccessResponse | ErrorResponse;

    if ("error" in response) {
      if (response.error.data.error === "ERROR_REPOSITORY_IN_USE") {
        toast.error(t("messages.delete.repositoryInUse"));
      } else {
        toast.error(t("messages.delete.error"));
      }
    } else {
      toast.success(t("messages.delete.success"));
    }

    setRepositoryToDelete(null);
  };

  const renderToolbar = () => {
    return (
      <Button
        onClick={() =>
          navigate(
            `/workspaces/${workspace.secondaryIdentifier}/repositories/new`,
          )
        }
      >
        <i className="pi pi-plus"></i>
      </Button>
    );
  };

  useEffect(() => {
    if (workspace) {
      fetchRepositories({ workspaceId: workspace.id });
    }
  }, [workspace, fetchRepositories]);

  return (
    <Page
      className="repositories-page"
      title={t("title.main")}
      loading={isFetching}
      toolbar={renderToolbar()}
    >
      {!data?.length ? (
        <Message message={t("messages.noRepositories")} />
      ) : (
        data?.map((repository) => (
          <RepositoryCard
            key={repository.id}
            repository={repository}
            onDelete={handleDelete}
          />
        ))
      )}
      <ConfirmationModal
        accept={deleteHandler}
        header={t("confirmation.header")}
        message={t("confirmation.message", {
          repositoryName: repositoryToDelete?.name,
        })}
        acceptButtonOptions={{ loading: isLoading, severity: "danger" }}
        visible={!!repositoryToDelete}
        onHide={() => setRepositoryToDelete(null)}
      />
    </Page>
  );
};

export default Repositories;
