import { StateTransition, State } from "@12degrees/stateful-core";
import { useTranslation } from "@dzangolab/react-i18n";
import { ConfirmationModal } from "@dzangolab/react-ui";
import { useEffect, useState } from "react";

import "./index.css";
import { RELEASE_STATUS_APPROVED } from "../../constants";

interface Properties {
  allStatuses: State[];
  allowedTransitions: StateTransition[];
  value?: number;
  onReleaseChange: (value: number) => void;
}

const ReleaseStatusWidget: React.FC<Properties> = (properties) => {
  const { t } = useTranslation("release");

  const { allStatuses, allowedTransitions, value, onReleaseChange } =
    properties;

  const [selectedStatus, setSelectedStatus] = useState<State>();
  const [nextTransitionId, setNextTransitionId] = useState<number>();

  const handleReleaseChange = (transition: StateTransition) => {
    const approvedStatus = allStatuses.find(
      (status) => status.slug === RELEASE_STATUS_APPROVED,
    );

    if (approvedStatus?.id === transition.toStateId) {
      setNextTransitionId(transition.id);
    } else {
      onReleaseChange(transition.id);
    }
  };

  const onAccept = () => {
    if (!nextTransitionId) return;

    onReleaseChange(nextTransitionId);
    setNextTransitionId(undefined);
  };

  useEffect(() => {
    const status = allStatuses.find((status) => {
      return status.id === value;
    });

    if (status) {
      setSelectedStatus(status);
    }
  }, [allStatuses, value]);

  const renderLeftButton = () => {
    const transition = allowedTransitions.find(
      (transition) =>
        selectedStatus?.id === transition.fromStateId &&
        transition.isRegressive,
    );

    if (!transition) return null;

    const target = allStatuses.find(
      (status) => status.id === transition.toStateId,
    );

    if (!target) return null;

    return (
      <button
        className="control control-left"
        onClick={() => handleReleaseChange(transition)}
      >
        <i className="pi pi-angle-left" />
        <span>{target.i18n ? target.i18n[0].name : target.slug}</span>
      </button>
    );
  };

  const renderRightButton = () => {
    const transition = allowedTransitions.find(
      (transition) =>
        selectedStatus?.id === transition.fromStateId &&
        transition.isProgressive,
    );

    if (!transition) return null;

    const target = allStatuses.find(
      (status) => status.id === transition.toStateId,
    );

    if (!target) return null;

    return (
      <button
        className="control control-right"
        onClick={() => handleReleaseChange(transition)}
      >
        <i className="pi pi-angle-right" />
        <span>{target.i18n ? target.i18n[0].name : target.slug}</span>
      </button>
    );
  };

  return (
    <div className="release-status-widget">
      {renderLeftButton()}
      <span>
        {selectedStatus?.i18n
          ? selectedStatus?.i18n[0]?.name
          : selectedStatus?.slug}
      </span>
      {renderRightButton()}
      <ConfirmationModal
        accept={onAccept}
        header={t("release-status.confirmation.header")}
        message={t("release-status.confirmation.message")}
        visible={!!nextTransitionId}
        onHide={() => setNextTransitionId(undefined)}
      />
    </div>
  );
};

export default ReleaseStatusWidget;
