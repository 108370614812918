import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { GlossaryEntry, GlossaryLocales, SupportedLocales } from "core";
import { toast } from "react-toastify";
import { z } from "zod";

import GlossaryEntryFormFields from "./GlossaryEntryFormFields";
import {
  useCreateGlossaryEntryMutation,
  useUpdateGlossaryEntryMutation,
} from "../../../../redux/apis/glossary";

interface Properties {
  entry?: GlossaryEntry;
  glossaryId: number;
  onFormSubmitSuccess: () => void;
  mainLocale: GlossaryLocales;
  supportedLocales: SupportedLocales[];
  glossaryLocales: GlossaryLocales[];
}

const GlossaryEntryForm: React.FC<Properties> = (properties) => {
  const { t } = useTranslation("glossary");
  const {
    entry,
    onFormSubmitSuccess,
    glossaryId,
    glossaryLocales,
    mainLocale,
    supportedLocales,
  } = properties;

  const [createGlossaryEntry, { isLoading: isCreatingGlossaryEntry }] =
    useCreateGlossaryEntryMutation();

  const [updateGlossaryEntry, { isLoading: isUpdatingGlossaryEntry }] =
    useUpdateGlossaryEntryMutation();

  const onFormSubmit = async (data: any, redirectAfterSubmission?: boolean) => {
    if (!entry) {
      let response = await createGlossaryEntry({
        glossaryId,
        data: data.glossaryEntryI18n,
      });

      if ("error" in response) {
        toast.error(t("glossary-entry.message.error.default"));
      } else {
        toast.success(t("glossary-entry.message.create-success"));
      }
    } else {
      let response = await updateGlossaryEntry({
        glossaryId,
        entryId: entry.id,
        data: data.glossaryEntryI18n,
      });

      if ("error" in response) {
        toast.error(t("glossary-entry.message.error.default"));
      } else {
        toast.success(t("glossary-entry.message.update-success"));
      }
    }

    if (redirectAfterSubmission) {
      onFormSubmitSuccess();
    }
  };

  const formValidationSchema = z
    .object({
      glossaryEntryI18n: z
        .object({
          term: z.string(),
          definition: z.string(),
          locale: z
            .string()
            .min(1, t("glossary-entry.form.validations.locale.required")),
        })
        .array(),
    })
    .superRefine(({ glossaryEntryI18n }, context) => {
      let index = 0;

      for (const entry of glossaryEntryI18n) {
        if (entry.definition && !entry.term) {
          context.addIssue({
            code: "custom",
            message: t("glossary-entry.form.validations.localeTerm.required"),
            path: ["glossaryEntryI18n", index, "term"],
          });
        }

        if (entry.locale === mainLocale?.localeCode && !entry.term) {
          context.addIssue({
            code: "custom",
            message: t(
              "glossary-entry.form.validations.mainLocaleTerm.required",
            ),
            path: ["glossaryEntryI18n", index, "term"],
          });
        }

        index++;
      }
    });

  const defaultValues = {
    glossaryEntryI18n: [
      {
        term: "",
        definition: "",
        locale: "",
      },
    ],
  };

  const getInitialValues = () => {
    if (entry) {
      const mainLocaleEntry = entry.i18n.find(
        (locale) => locale.locale === mainLocale.localeCode,
      );

      const nonMainLocales = entry.i18n.filter(
        (locale) => locale.locale !== mainLocale.localeCode,
      );

      const newlyAddedLocales = glossaryLocales.filter((locale) => {
        return !entry.i18n.some((i18n) => i18n.locale === locale.localeCode);
      });

      return {
        glossaryEntryI18n: [
          {
            term: mainLocaleEntry?.term || "",
            definition: mainLocaleEntry?.definition || "",
            locale: mainLocaleEntry?.locale || mainLocale.localeCode,
          },
          ...nonMainLocales.map((i18n) => {
            return {
              term: i18n.term,
              definition: i18n.definition,
              locale: i18n.locale,
            };
          }),
          ...newlyAddedLocales.map((locale) => {
            return {
              term: "",
              definition: "",
              locale: locale.localeCode,
            };
          }),
        ],
      };
    }

    return {
      glossaryEntryI18n: [mainLocale, ...glossaryLocales].map((locale) => {
        return {
          term: "",
          definition: "",
          locale: locale.localeCode,
        };
      }),
    };
  };

  return (
    <Provider
      onSubmit={(data: any) => onFormSubmit(data, true)}
      validationSchema={formValidationSchema}
      defaultValues={defaultValues}
      values={getInitialValues()}
      className="glossary-entry-form"
    >
      {mainLocale && glossaryLocales && supportedLocales && (
        <GlossaryEntryFormFields
          entry={entry}
          glossaryId={glossaryId}
          glossaryLocales={glossaryLocales}
          isSubmitting={isCreatingGlossaryEntry || isUpdatingGlossaryEntry}
          mainLocale={mainLocale}
          supportedLocales={supportedLocales}
          onSubmit={onFormSubmit}
        />
      )}
    </Provider>
  );
};

export default GlossaryEntryForm;
