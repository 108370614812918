import { useTranslation } from "@dzangolab/react-i18n";
import { Page } from "@dzangolab/react-ui";
import { GlossaryLocales } from "core";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { GlossaryEntryForm } from "./components";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import {
  useLazyGetGlossaryQuery,
  useLazyGetSupportedLocalesQuery,
} from "../../redux/apis/glossary";

const AddGlossaryEntry = () => {
  const navigate = useNavigate();
  const parameters = useParams();
  const { t } = useTranslation("glossary");
  const glossaryId = parseInt(parameters.id ?? "");

  const [mainLocale, setMainLocale] = useState<GlossaryLocales | undefined>();
  const [glossaryLocales, setGlossaryLocales] = useState<GlossaryLocales[]>([]);

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const [fetchGlossary, { data: glossary }] = useLazyGetGlossaryQuery();
  const [fetchSupportedLocales, { data: supportedLocales }] =
    useLazyGetSupportedLocalesQuery();

  useEffect(() => {
    if (glossaryId) {
      fetchGlossary(glossaryId);
      fetchSupportedLocales();
    }
  }, [fetchGlossary, fetchSupportedLocales, glossaryId]);

  useEffect(() => {
    if (glossary) {
      const mainLocale = glossary.locales.find((locale) => locale.mainLocale);
      const glossaryLocales = glossary.locales.filter(
        (locale) => !locale.mainLocale,
      );

      if (mainLocale) {
        setMainLocale(mainLocale);
      }

      setGlossaryLocales(glossaryLocales);
    }
  }, [glossary]);

  const onFormSubmitSuccess = () => {
    if (currentWorkspace && currentWorkspace.secondaryIdentifier) {
      navigate(`/workspaces/${currentWorkspace.secondaryIdentifier}/glossary`);
    }
  };

  return (
    <Page className="glossary-page" title={t("glossary-entry.create.title")}>
      {currentWorkspace && glossary && mainLocale && supportedLocales && (
        <GlossaryEntryForm
          glossaryId={glossaryId}
          onFormSubmitSuccess={onFormSubmitSuccess}
          mainLocale={mainLocale}
          glossaryLocales={glossaryLocales}
          supportedLocales={supportedLocales}
        />
      )}
    </Page>
  );
};

export default AddGlossaryEntry;
