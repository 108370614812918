import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Page } from "@dzangolab/react-ui";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LocaleSetting } from "./components";
import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";
import {
  useLazyGetGlossaryQuery,
  useLazyGetSupportedLocalesQuery,
  useLazyGetWorkspaceGlossaryQuery,
} from "../../../redux/apis/glossary";

const GlossarySettings = () => {
  const { t } = useTranslation("glossary");
  const navigate = useNavigate();
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const [fetchWorkspaceGlossary, { data: workspaceGlossary }] =
    useLazyGetWorkspaceGlossaryQuery();

  const [fetchGlossary, { data: glossary, isLoading: isLoadingGlossary }] =
    useLazyGetGlossaryQuery();

  const [
    fetchSupportedLocales,
    { data: supportedLocales, isLoading: isLoadingSupportedLocales },
  ] = useLazyGetSupportedLocalesQuery();

  const navigateToCreateGlossary = useCallback(() => {
    if (currentWorkspace && currentWorkspace.secondaryIdentifier) {
      navigate(
        `/workspaces/${currentWorkspace.secondaryIdentifier}/glossary/start`,
      );
    }
  }, [currentWorkspace, navigate]);

  const navigateToGlossary = () => {
    if (currentWorkspace && currentWorkspace.secondaryIdentifier && glossary) {
      navigate(`/workspaces/${currentWorkspace.secondaryIdentifier}/glossary`);
    }
  };

  useEffect(() => {
    if (currentWorkspace) {
      fetchWorkspaceGlossary(currentWorkspace.id).then((response: any) => {
        if (
          "error" in response &&
          response.error.status === 404 &&
          response.error.data.error === "ERROR_WORKSPACE_GLOSSARY_NOT_FOUND"
        ) {
          navigateToCreateGlossary();
        }
      });
    }
  }, [currentWorkspace, fetchWorkspaceGlossary, navigateToCreateGlossary]);

  useEffect(() => {
    if (workspaceGlossary && workspaceGlossary.glossaryId) {
      fetchGlossary(workspaceGlossary.glossaryId);
    }
  }, [fetchGlossary, workspaceGlossary]);

  useEffect(() => {
    fetchSupportedLocales();
  }, [fetchSupportedLocales]);

  return (
    <Page
      className="settings-page"
      title={t("glossary-settings.title")}
      loading={isLoadingGlossary || isLoadingSupportedLocales}
      toolbar={
        <Button
          onClick={navigateToGlossary}
          label={t("glossary-settings.actions.goto-glossary")}
        />
      }
    >
      {glossary && supportedLocales && (
        <LocaleSetting
          glossary={glossary}
          supportedLocales={supportedLocales ?? []}
        />
      )}
    </Page>
  );
};

export default GlossarySettings;
