import { parse } from "@dzangolab/react-config";
import { UserConfig } from "@dzangolab/react-user";

import { ROUTES } from "./constants";
import en from "./locales/en";
import fr from "./locales/fr";
import TermsAndConditions from "./views/TermsAndConditions";

import type { AppConfig, AppFeatures } from "@dzangolab/react-config";
declare module "@dzangolab/react-config" {
  interface AppConfig {
    apiWebsocketUrl: string;
    features: AppFeatures;
    sentry: {
      dsn: string;
      environment: string;
    };
  }
}
const config: AppConfig = {
  apiBaseUrl: import.meta.env.VITE_API_BASE_URL as string,
  apiWebsocketUrl: import.meta.env.VITE_API_WEBSOCKET_URL as string,
  appName: import.meta.env.VITE_APP_NAME as string,
  appPort: parse(import.meta.env.VITE_APP_PORT, "20072") as string,
  appTitle: import.meta.env.VITE_APP_TITLE as string,
  appVersion: `${import.meta.env.VITE_APP_VERSION}+${
    import.meta.env.VITE_APP_BUILD
  }` as string,
  copyright: {
    holder: import.meta.env.VITE_APP_COPYRIGHT_HOLDER as string,
    url: import.meta.env.VITE_APP_COPYRIGHT_HOLDER_URL as string,
  },
  features: {
    branchFilter: parse(
      import.meta.env.VITE_FEATURE_BRANCH_FILTER as string,
      false,
    ),
    documentation: parse(
      import.meta.env.VITE_FEATURE_DOCUMENTATION_ENABLED as string,
      false,
    ),
    showVersion: parse(
      import.meta.env.VITE_FEATURE_SHOW_VERSION as string,
      true,
    ) as boolean,
  } as AppFeatures,
  i18n: {
    appendNamespaceToCIMode: true,
    debug: true,
    defaultNS: "app",
    fallbackLng: (import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE ||
      "en") as string,
    resources: {
      en: en,
      fr: fr,
    },
    supportedLngs: (
      (import.meta.env.VITE_I18N_LOCALES as string) || "en"
    ).split(","),
  },
  layout: {
    logo: "/logo.svg",
    logoRoute: ROUTES.WORKSPACES,
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || "development",
  },
  websiteDomain: (window.location.protocol +
    "//" +
    window.location.host) as string,
};

const feature = (name: keyof AppFeatures): boolean => {
  return config.features[name];
};

export const userConfig: UserConfig = {
  apiBaseUrl: config.apiBaseUrl,
  appDomain: config.websiteDomain,
  supportedRoles: ["USER"],
  // socialLoginProviders: ["google"],
  features: {
    emailVerification: parse(
      import.meta.env.VITE_FEATURE_EMAIL_VERIFICATION_ENABLED,
      false,
    ),
    termsAndConditions: {
      display: true,
      showCheckbox: true,
      label: <TermsAndConditions />,
    },
  },
  supertokens: {
    appName: config.appName as string,
    apiDomain: config.apiBaseUrl,
  },
};

export default config;

export { feature };
