import { Glossary, GlossaryEntry, SupportedLocales } from "core";

import baseApi, { TagsEnum } from "./base-api";

const docsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getGlossary: build.query<Glossary, number>({
      query: (glossaryId: number) => {
        return {
          url: `/glossaries/${glossaryId}`,
        };
      },
      providesTags: [TagsEnum.Glossary],
      keepUnusedDataFor: 0,
    }),
    getSupportedLocales: build.query<SupportedLocales[], void>({
      query: () => {
        return {
          url: `/glossaries/supported-locales`,
        };
      },
      providesTags: [TagsEnum.SupportedLocales],
    }),
    createGlossary: build.mutation<any, { workspaceId: number; data: any }>({
      query: ({ workspaceId, data }) => {
        return {
          url: `/glossaries`,
          method: "POST",
          body: {
            workspaceId,
            glossary: data,
          },
        };
      },
      invalidatesTags: [TagsEnum.WorkspaceGlossary],
    }),
    createGlossaryEntry: build.mutation<any, { glossaryId: number; data: any }>(
      {
        query: ({ glossaryId, data }) => {
          return {
            url: `/glossaries/${glossaryId}/entries`,
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: [TagsEnum.GlossaryEntry],
      },
    ),
    deleteGlossaryEntry: build.mutation<
      any,
      { glossaryId: number; entryId: number }
    >({
      query: ({ glossaryId, entryId }) => {
        return {
          url: `/glossaries/${glossaryId}/entries/${entryId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [TagsEnum.GlossaryEntry],
    }),
    updateGlossaryEntry: build.mutation<
      GlossaryEntry,
      { glossaryId: number; entryId: number; data: any }
    >({
      query: ({ glossaryId, entryId, data }) => {
        return {
          url: `/glossaries/${glossaryId}/entries/${entryId}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.GlossaryEntry],
    }),
    updateGlossary: build.mutation<any, { glossaryId: number; data: any }>({
      query: ({ glossaryId, data }) => {
        return {
          url: `/glossaries/${glossaryId}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.Glossary],
    }),
    getWorkspaceGlossary: build.query<any, number>({
      query: (workspaceId: number) => {
        return {
          url: `workspaces/${workspaceId}/glossary`,
        };
      },
      providesTags: [TagsEnum.WorkspaceGlossary],
    }),
    getGlossaryEntry: build.query<
      GlossaryEntry,
      { glossaryId: number; entryId: number }
    >({
      query: (input: { glossaryId: number; entryId: number }) => {
        return {
          url: `/glossaries/${input.glossaryId}/entries/${input.entryId}`,
        };
      },
      providesTags: [TagsEnum.GlossaryEntry],
    }),
  }),
});

export const {
  useLazyGetWorkspaceGlossaryQuery,
  useLazyGetGlossaryQuery,
  useLazyGetGlossaryEntryQuery,
  useLazyGetSupportedLocalesQuery,
  useCreateGlossaryMutation,
  useCreateGlossaryEntryMutation,
  useUpdateGlossaryMutation,
  useUpdateGlossaryEntryMutation,
  useDeleteGlossaryEntryMutation,
} = docsApi;

export default docsApi;
