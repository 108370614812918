import { useTranslation } from "@dzangolab/react-i18n";
import { Page } from "@dzangolab/react-ui";

import "./NotFound.css";

const NotFound = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page>
      <div className="error-page">
        <h1>{t("pageNotFound.title")}</h1>
        <p>{t("pageNotFound.subtitle")}</p>
      </div>
    </Page>
  );
};

export default NotFound;
