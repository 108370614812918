import { useTranslation } from "@dzangolab/react-i18n";
import {
  TableColumnDefinition,
  TDataTable as DataTable,
  Tag,
  Button,
} from "@dzangolab/react-ui";
import { DocumentType } from "core";
import { useNavigate } from "react-router-dom";

interface Properties {
  documentTypes: DocumentType[];
  loading: boolean;
}

const DocumentTypesTable: React.FC<Properties> = (properties) => {
  const { documentTypes, loading } = properties;

  const navigate = useNavigate();
  const { t } = useTranslation("docs");

  const columns: Array<TableColumnDefinition<DocumentType>> = [
    {
      accessorKey: "name",
      header: t("docs-types.table.columns.name"),
      enableSorting: true,
    },
    {
      accessorKey: "slug",
      header: t("docs-types.table.columns.slug"),
      width: "10rem",
    },
    {
      accessorKey: "path",
      header: t("docs-types.table.columns.path"),
      width: "10rem",
    },
    {
      accessorKey: "global",
      header: t("docs-types.table.columns.global"),
      align: "center",
      width: "8rem",
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return <Tag label={original.workspaceId ? "No" : "Yes"} />;
      },
    },

    {
      accessorKey: "edit",
      header: () => <i className="pi pi-cog"></i>,
      align: "center",
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return (
          <Button
            iconLeft="pi pi-file-edit"
            onClick={() =>
              navigate(
                `/workspaces/${original.workspaceId}/docs/types/${original.id}/edit`,
              )
            }
          />
        );
      },
      width: "5rem",
    },
  ];

  return (
    <DataTable
      columns={columns}
      className="document-types-table"
      isLoading={loading}
      data={documentTypes}
      emptyTableMessage={t("docs-types.message.error.no-docs-types")}
      initialSorting={[
        {
          id: "name",
          desc: false,
        },
      ]}
    />
  );
};

export default DocumentTypesTable;
