import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { ConfirmationModal, Modal } from "@dzangolab/react-ui";
import { ReleaseApp } from "core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { z } from "zod";

import AddReleaseAppFormFields from "./ReleaseAppFormFields";
import { useCurrentWorkspace } from "../../../../hooks/UseCurrentWorkspace";
import {
  useAddReleaseAppMutation,
  useDeleteReleaseAppMutation,
  useUpdateReleaseAppMutation,
} from "../../../../redux/apis/release";

interface Properties {
  onHide: () => void;
  releaseApp?: ReleaseApp;
  visible: boolean;
  moduleType: string;
}

const AddReleaseAppDialog: React.FC<Properties> = (properties) => {
  const { onHide, releaseApp, visible, moduleType } = properties;
  const { t } = useTranslation("appOverview");
  const { workspace, release, refetchWorkspaceAndRelease } =
    useCurrentWorkspace();
  const [triggerAdd, { isLoading: isCreating, error }] =
    useAddReleaseAppMutation();
  const [triggerUpdate, { isLoading: isUpdating }] =
    useUpdateReleaseAppMutation();
  const [deleteReleaseApp] = useDeleteReleaseAppMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onAppDelete = async () => {
    if (!releaseApp || !release) return;

    setShowDeleteModal(false);
    onHide();

    await deleteReleaseApp({ releaseId: release.id, appId: releaseApp.id })
      .then(() => {
        refetchWorkspaceAndRelease();
        toast.success(t("release-app.delete.message.success"));
      })
      .catch(() => {
        toast.error(t("release-app.delete.message.error"));
      });
  };

  useEffect(() => {
    if (!isCreating && error) {
      toast.error(t("release-app.add.error"));
    }
  }, [error, isCreating, t]);

  const validationSchema = z.object({
    branch: z.string({
      required_error: t("release-app.add.form.validations.branch.required"),
    }),
    buildScript: z
      .string()
      .min(1, t("release-app.add.form.validations.buildScript.required")),
    description: z.string().nullable(),
    envVars: z
      .object({
        name: z
          .string()
          .min(1, t("release-app.add.form.validations.name.required")),
        value: z.union([z.string(), z.number(), z.boolean()]),
      })
      .array(),
    extensionConfig: z
      .string()
      .min(1, t("release-app.add.form.validations.extensionConfig.required"))
      .refine((value) => {
        try {
          if (value) {
            JSON.parse(value);
          }

          return true;
        } catch (_) {
          return false;
        }
      }, t("release-app.add.form.validations.extensionConfig.invalid"))
      .transform((value) => (value ? JSON.parse(value) : null))
      .nullable(),
    isPublic: z.boolean().nullable(),
    name: z
      .string()
      .min(1, t("release-app.add.form.validations.name.required")),
    port: z.number({
      invalid_type_error: t("release-app.add.form.validations.port.required"),
      required_error: t("release-app.add.form.validations.port.required"),
    }),
    proxyConfig: z
      .string()
      .refine((value) => {
        try {
          if (value) {
            JSON.parse(value);
          }

          return true;
        } catch (_) {
          return false;
        }
      }, t("release-app.add.form.validations.proxyConfig.invalid"))
      .transform((value) => (value ? JSON.parse(value) : null))
      .nullable(),
    repositoryId: z.number({
      invalid_type_error: t(
        "release-app.add.form.validations.repositoryId.required",
      ),
      required_error: t(
        "release-app.add.form.validations.repositoryId.required",
      ),
    }),
    runScript: z
      .string()
      .min(1, t("release-app.add.form.validations.runScript.required")),
    slug: z
      .string()
      .min(1, t("release-app.add.form.validations.slug.required")),
    sourcePath: z.string().nullable(),
    type: z
      .string()
      .min(1, t("release-app.add.form.validations.type.required"))
      .optional(),
  });

  const defaultValues = {
    branch: "",
    buildScript: "",
    envVars: [],
    extensionConfig: "",
    isPublic: false,
    name: "",
    proxyConfig: "",
    repositoryId: null,
    runScript: "",
    slug: "",
    sourcePath: "",
  };

  const getInitialValues = () => {
    if (!releaseApp) return defaultValues;

    const extensionConfig = releaseApp.extensionConfig
      ? JSON.stringify(releaseApp.extensionConfig)
      : "";

    const proxyConfig = releaseApp.proxyConfig
      ? JSON.stringify(releaseApp.proxyConfig)
      : "";

    const envVariables = releaseApp.envVars
      ? Object.keys(releaseApp.envVars).map((key) => ({
          name: key,
          value: releaseApp.envVars[key],
        }))
      : [];

    return {
      branch: releaseApp.branch,
      buildScript: releaseApp.buildScript || "",
      envVars: envVariables,
      description: releaseApp.description || "",
      extensionConfig,
      isPublic: releaseApp.isPublic || false,
      name: releaseApp.name,
      port: releaseApp.port,
      proxyConfig,
      repositoryId: releaseApp.repositoryId,
      runScript: releaseApp.runScript || "",
      slug: releaseApp.slug,
      sourcePath: releaseApp.sourcePath || "",
      type: releaseApp.type,
    };
  };

  const handleSubmit = async (data: z.infer<typeof validationSchema>) => {
    const envVariables = data.envVars.reduce(
      (result: Record<string, string>, { name, value }) => {
        result[name] = String(value);

        return result;
      },
      {},
    );

    if (releaseApp) {
      await triggerUpdate({
        id: releaseApp.id,
        data: {
          ...data,
          envVars: envVariables,
          releaseId: release.id,
        },
      })
        .then(() => {
          refetchWorkspaceAndRelease();
          toast.success(t("release-app.update.message.success"));
        })
        .catch(() => {
          toast.error(t("release-app.update.message.error"));
        });
    } else {
      triggerAdd({
        ...data,
        envVars: envVariables,
        releaseId: release.id,
        type: moduleType,
      });
    }

    onHide();
  };

  return (
    <Modal
      onHide={onHide}
      visible={visible}
      header={
        releaseApp
          ? `${t("release-app.update.title")} ${releaseApp.name}`
          : `${t("release-app.add.title", {
              appModule: moduleType.slice(0, -1),
            })}`
      }
      className="add-release-app-dialog"
    >
      <Provider
        defaultValues={getInitialValues()}
        onSubmit={handleSubmit}
        className="release-app-form"
        validationSchema={validationSchema}
      >
        <AddReleaseAppFormFields
          releaseApp={releaseApp}
          workspace={workspace}
          handleCancel={onHide}
          handleDelete={() => setShowDeleteModal(true)}
          isLoading={isCreating || isUpdating}
        />
      </Provider>
      <ConfirmationModal
        accept={onAppDelete}
        acceptButtonOptions={{
          label: t("release-app.delete.confirmation.buttonLabel.accept", {
            appModule: releaseApp?.name,
          }),
          severity: "danger",
          variant: "outlined",
        }}
        cancelButtonOptions={{
          label: t("release-app.delete.confirmation.buttonLabel.cancel"),
        }}
        header={t("release-app.delete.confirmation.header", {
          appModule: releaseApp?.name,
        })}
        message={t("release-app.delete.confirmation.message", {
          appModule: releaseApp?.name,
        })}
        visible={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      />
    </Modal>
  );
};

export default AddReleaseAppDialog;
