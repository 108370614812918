import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Modal } from "@dzangolab/react-ui";
import { ReleaseType } from "core";
import { useState } from "react";
import { toast } from "react-toastify";
import { z } from "zod";

import ReleaseTypeFormFields from "./ReleaseTypeFormFields";
import { useCurrentWorkspace } from "../../../../../hooks/UseCurrentWorkspace";
import {
  useCreateReleaseTypeMutation,
  useUpdateReleaseTypeMutation,
} from "../../../../../redux/apis/release";

interface Properties {
  onHide: () => void;
  releaseType?: ReleaseType;
  visible: boolean;
}

interface ErrorResponse {
  error: {
    status: number;
    data: {
      error: string;
      message: string;
    };
  };
}

interface SuccessResponse {
  data: ReleaseType;
}

const ReleaseTypeFormModal: React.FC<Properties> = ({
  onHide,
  releaseType,
  visible,
}) => {
  const { t } = useTranslation("releaseTypeForm");

  const { workspace } = useCurrentWorkspace();
  const [triggerAdd, { isLoading: isCreating }] =
    useCreateReleaseTypeMutation();

  const [triggerUpdate, { isLoading: isUpdating }] =
    useUpdateReleaseTypeMutation();

  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});

  const closeModal = () => {
    setFieldErrors({});
    onHide();
  };

  const formValidationSchema = z.object({
    name: z.string().min(1),
    description: z.string().nullable(),
    slug: z.string().min(1),
  });

  const defaultValues = {
    description: releaseType?.description ?? null,
    name: releaseType?.name ?? "",
    slug: releaseType?.slug ?? "",
  };

  const handleSubmit = async (data: z.infer<typeof formValidationSchema>) => {
    if (!workspace) return;

    let response: SuccessResponse | ErrorResponse;

    try {
      if (releaseType) {
        response = (await triggerUpdate({
          data: {
            ...data,
            workspaceId: workspace.id,
          },
          id: releaseType.id,
        })) as SuccessResponse | ErrorResponse;
      } else {
        response = (await triggerAdd({
          ...data,
          workspaceId: workspace.id,
        })) as SuccessResponse | ErrorResponse;
      }

      if ("data" in response) {
        const successMessage = releaseType
          ? t("release-types.message.success.release-types-updated")
          : t("release-types.message.success.release-types-added");

        toast.success(successMessage);

        closeModal();
      } else if ("error" in response) {
        handleErrorResponse(response.error);
      }
    } catch {
      toast.error(t("release-types.message.error.default"));
    }
  };

  const handleErrorResponse = (error: ErrorResponse["error"]) => {
    if (error.data.error === "ERROR_SLUG_ALREADY_EXISTS") {
      setFieldErrors({
        slug: t("release-types.message.error.release-type-slug-already-exists"),
      });
    } else {
      toast.error(t("release-types.message.error.default"));
    }
  };

  return (
    <Modal
      className="release-form-modal"
      header={
        releaseType
          ? t("release-types.actions.edit")
          : t("release-types.actions.new")
      }
      onHide={closeModal}
      visible={visible}
    >
      <div className="release-types-form-wrapper">
        <Provider
          onSubmit={handleSubmit}
          validationSchema={formValidationSchema}
          defaultValues={defaultValues}
        >
          <ReleaseTypeFormFields
            actionLabel={
              releaseType
                ? t("release-types.actions.update")
                : t("release-types.actions.add")
            }
            fieldErrors={fieldErrors}
            isLoading={isCreating || isUpdating}
            handleCancel={closeModal}
            isEdit={!!releaseType}
          />
        </Provider>
      </div>
    </Modal>
  );
};

export default ReleaseTypeFormModal;
