import {
  RadioInput,
  Select,
  Textarea,
  useFormContext,
} from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button, SubmitButton } from "@dzangolab/react-ui";
import { BranchMatchingRuleCreateInput, ReleaseType } from "core";

interface Properties {
  actionLabel?: string;
  fieldErrors?: Record<string, string>;
  handleCancel: () => void;
  isLoading: boolean;
  releaseTypes?: ReleaseType[];
  ruleTypes?: { id: number; name: string }[];
}

const BranchRuleFormFields: React.FC<Properties> = (properties) => {
  const { actionLabel, handleCancel, isLoading, releaseTypes, ruleTypes } =
    properties;
  const { t } = useTranslation("repositoryDetails");

  const {
    formState: { errors },
    register,
  } = useFormContext<BranchMatchingRuleCreateInput>();

  const getErrorMessage = (
    field: keyof BranchMatchingRuleCreateInput,
  ): string => {
    return (errors[field]?.message as string) || "";
  };

  const getOptions = (
    data: { id: number; name: string }[] | undefined,
  ): { label: string; value: string }[] => {
    return (
      ruleTypes?.map((rule) => ({
        label: rule.name,
        value: rule.id.toString(),
      })) ?? []
    );
  };

  return (
    <>
      <Select
        label={t("form.fields.releaseType.label")}
        name="releaseTypeId"
        options={
          releaseTypes?.map((releaseType) => ({
            label: releaseType.name,
            value: releaseType.id,
          })) ?? []
        }
        placeholder={t("form.fields.releaseType.placeholder")}
      />
      <RadioInput
        label={t("form.fields.ruleType.label")}
        name="ruleTypeId"
        options={getOptions(ruleTypes)}
      />
      <Textarea
        label={t("form.fields.includePatterns.label")}
        name="includePatterns"
        errorMessage={getErrorMessage("includePatterns")}
        placeholder={t("form.fields.includePatterns.placeholder")}
      />
      <Textarea
        label={t("form.fields.excludePatterns.label")}
        name="excludePatterns"
        errorMessage={getErrorMessage("excludePatterns")}
        placeholder={t("form.fields.excludePatterns.placeholder")}
      />
      <div className="checkbox-wrapper">
        <input id="isActive" type="checkbox" {...register("isActive")} />
        <label htmlFor="isActive">{t("form.fields.active")}</label>
      </div>
      <Button
        label={t("form.actions.cancel")}
        type="button"
        onClick={handleCancel}
        severity="secondary"
        variant="outlined"
      />
      <SubmitButton
        label={actionLabel ?? t("form.actions.submit")}
        loading={isLoading}
      />
    </>
  );
};

export default BranchRuleFormFields;
