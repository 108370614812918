/* import { useEmojiMenu } from "@/components/emoji-menu";
import { useSetInspector } from "@/components/playground-editor/InspectorProvider";
import { useSlash } from "@/components/slash-menu"; */
import {
  defaultValueCtx,
  Editor,
  editorViewOptionsCtx,
  rootCtx,
} from "@milkdown/core";
import { Ctx, MilkdownPlugin } from "@milkdown/ctx";
// import { block } from "@milkdown/plugin-block";
// import { clipboard } from "@milkdown/plugin-clipboard";
import { collab, collabServiceCtx } from "@milkdown/plugin-collab";
// import { cursor } from "@milkdown/plugin-cursor";
import { diagram, diagramSchema } from "@milkdown/plugin-diagram";
/* import { emoji, emojiAttr } from "@milkdown/plugin-emoji";
import { history } from "@milkdown/plugin-history";
import { indent } from "@milkdown/plugin-indent"; */
import { listener, listenerCtx } from "@milkdown/plugin-listener";
/* import { math, mathBlockSchema } from "@milkdown/plugin-math";
import { prism, prismConfig } from "@milkdown/plugin-prism";
import { trailing } from "@milkdown/plugin-trailing";
import { upload } from "@milkdown/plugin-upload"; */
import { commonmark } from "@milkdown/preset-commonmark";
import { gfm } from "@milkdown/preset-gfm";
import { useEditor } from "@milkdown/react";
import { nord } from "@milkdown/theme-nord";
import { $view } from "@milkdown/utils";
import {
  useNodeViewFactory,
  usePluginViewFactory,
  useWidgetViewFactory,
} from "@prosemirror-adapter/react";
import React, { useEffect, useMemo } from "react";
import { WebsocketProvider } from "y-websocket";
import { Doc } from "yjs";

/* import debounce from "lodash.debounce";
import { useRouter } from "next/navigation"; */

/* import { refractor } from "refractor/lib/common";
import { Block } from "@/components/playground-editor/editor-component/Block";
import { CodeBlock } from "@/components/playground-editor/editor-component/CodeBlock";
import { Diagram } from "@/components/playground-editor/editor-component/Diagram"; */
/* import {
  FootnoteDef,
  FootnoteRef,
} from "@/components/playground-editor/editor-component/Footnote"; */
/* import {
  ImageTooltip,
  imageTooltip,
} from "@/components/playground-editor/editor-component/ImageTooltip";
// import { linkPlugin } from "@/components/playground-editor/editor-component/LinkWidget";
import { ListItem } from "@/components/playground-editor/editor-component/ListItem";
import { MathBlock } from "@/components/playground-editor/editor-component/MathBlock";
import {
  tableSelectorPlugin,
  TableTooltip,
  tableTooltip,
  tableTooltipCtx,
} from "@/components/playground-editor/editor-component/TableWidget";
import { encode } from "@/utils/share";
import { useSetShare } from "./ShareProvider";
import { useToast } from "../toast";
import { useFeatureToggle } from "./FeatureToggleProvider";
import { useSetProseState } from "./ProseStateProvider"; */
import { Diagram } from "./Diagram";
import { tooltip } from "./LinkTooltip/tooltip";
import {
  tableSelectorPlugin,
  TableTooltip,
  tableTooltip,
  tableTooltipContext,
} from "./TableWidget";

export const useMarkdownEditor = (
  defaultValue: string,
  editable: boolean,
  collabConfig?: {
    roomId: string;
    userName: string;
    websocketUrl: string;
  },
  setEditorContent?: React.Dispatch<React.SetStateAction<string | null>>,
) => {
  let editMode = editable;

  let sharedDocument: Doc;
  let wsProvider: WebsocketProvider;

  if (editable && collabConfig) {
    sharedDocument = new Doc();

    wsProvider = new WebsocketProvider(
      collabConfig?.websocketUrl || "",
      collabConfig?.roomId || "",
      sharedDocument,
    );
  }

  const pluginViewFactory = usePluginViewFactory();
  const nodeViewFactory = useNodeViewFactory();
  const widgetViewFactory = useWidgetViewFactory();
  /*   const setProseState = useSetProseState();
    const setShare = useSetShare();
    const setInspector = useSetInspector();
    const toast = useToast();
    const {
      enableGFM,
      enableMath,
      enableDiagram,
      enableBlockHandle,
      enableTwemoji,
    } = useFeatureToggle(); */

  const gfmPlugins: MilkdownPlugin[] = useMemo(() => {
    return [
      gfm,
      tableTooltip,
      tableTooltipContext,
      (context: Ctx) => async () => {
        context.set(tableTooltip.key, {
          view: pluginViewFactory({
            component: TableTooltip,
          }),
        });
      },
      tableSelectorPlugin(widgetViewFactory),
    ].flat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeViewFactory, pluginViewFactory, widgetViewFactory]);

  const diagramPlugins: MilkdownPlugin[] = useMemo(() => {
    return [
      diagram,
      $view(diagramSchema.node, () =>
        nodeViewFactory({
          component: Diagram,
          stopEvent: () => true,
        }),
      ),
    ].flat();
  }, [nodeViewFactory]);

  /* const mathPlugins: MilkdownPlugin[] = useMemo(() => {
    return [
      $view(mathBlockSchema.node, () =>
        nodeViewFactory({
          component: MathBlock,
          stopEvent: () => true,
        })
      ),
      math,
    ].flat();
  }, [nodeViewFactory]);



  const blockPlugins: MilkdownPlugin[] = useMemo(() => {
    return [
      block,
      (ctx: Ctx) => () => {
        ctx.set(block.key, {
          view: pluginViewFactory({
            component: Block,
          }),
        });
      },
    ].flat();
  }, [pluginViewFactory]);

  const twemojiPlugins: MilkdownPlugin[] = useMemo(() => {
    return [
      emoji,
      (ctx: Ctx) => () => {
        ctx.set(emojiAttr.key, () => ({
          container: {},
          img: {
            class: "w-[1em] h-[1em] inline align-text-top",
          },
        }));
      },
    ].flat();
  }, []);

  const slash = useSlash();
  const emojiMenu = useEmojiMenu(); */

  const editorInfo = useEditor(
    (root) => {
      return (
        Editor.make()
          .enableInspector()
          .config((context) => {
            /* ctx.update(editorViewOptionsCtx, (prev) => ({
            ...prev,
            attributes: {
              class: "h-full mx-auto px-2 py-4 box-border",
            },
          })); */
            context.set(rootCtx, root);
            context.set(defaultValueCtx, defaultValue);
            // context.set(tooltip.key, {
            //   view: pluginViewFactory({
            //     component: TooltipView,
            //   }),
            // });

            context.get(listenerCtx).markdownUpdated((_, markdown) => {
              setEditorContent && setEditorContent(markdown);
            });
            context.update(editorViewOptionsCtx, (previous) => ({
              ...previous,
              editable: () => editMode,
            }));
            /* .updated((_, doc) => {
            const state = doc.toJSON();
            // debounce(setProseState, 100)(state);
            setProseState(state);
          }); */
            /* ctx.update(prismConfig.key, (prev) => ({
            ...prev,
            configureRefractor: () => refractor,
          })); */
            /* ctx.set(imageTooltip.key, {
            view: pluginViewFactory({
              component: ImageTooltip,
            }),
          });
          slash.config(ctx);
          emojiMenu.config(ctx); */
            // configureLinkTooltip(context);
          })
          .config(nord)
          //.use(emojiMenu.plugins)
          .use(commonmark)
          // .use(linkTooltipPlugin)
          //.use(linkPlugin(widgetViewFactory))
          .use(listener)
          .use(tooltip)
          .use(gfmPlugins)
          .use(diagramPlugins)
          .use(collab)
      );
      /* .use(clipboard)
      .use(history)
      .use(cursor)
      .use(prism)
      .use(indent)
      .use(upload)
      .use(trailing)
      .use(imageTooltip)
      .use(slash.plugins)
      .use(
        $view(listItemSchema.node, () =>
          nodeViewFactory({ component: ListItem })
        )
      )
      .use(
        $view(codeBlockSchema.node, () =>
          nodeViewFactory({ component: CodeBlock })
        )
      ); */
    },
    [defaultValue, editable],
  );

  const { get, loading } = editorInfo;

  const initializeCollab = async () => {
    const editor = get();

    if (!editor) return;

    wsProvider.on("connection-close", () => {
      wsProvider.destroy();
      // sharedDocument.destroy();
    });

    wsProvider.on("status", (event: { status: string }) => {
      if (event.status === "connected") {
        wsProvider.awareness.setLocalStateField("user", {
          name: collabConfig?.userName,
        });
      }
    });

    editor.action((context) => {
      const collabService = context.get(collabServiceCtx);

      collabService.bindDoc(sharedDocument).setAwareness(wsProvider.awareness);

      wsProvider.once("synced", async (isSynced: boolean) => {
        if (isSynced) {
          // eslint-disable-next-line no-console
          console.log("Collab websocket is synced");
          collabService.connect();
        } else {
          // eslint-disable-next-line no-console
          console.warn("Collab websocket is not synced");
        }
      });
    });
  };

  useEffect(() => {
    if (editable && collabConfig) {
      initializeCollab();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get, editable]);

  useEffect(() => {
    requestAnimationFrame(() => {
      const effect = async () => {
        const editor = get();

        if (!editor) return;

        /* if (enableGFM) {
          editor.use(gfmPlugins);
        } else {
          await editor.remove(gfmPlugins);
        }
        if (enableMath) {
          editor.use(mathPlugins);
        } else {
          await editor.remove(mathPlugins);
        }
        if (enableDiagram) {
          editor.use(diagramPlugins);
        } else {
          await editor.remove(diagramPlugins);
        }
        if (enableBlockHandle) {
          editor.use(blockPlugins);
        } else {
          await editor.remove(blockPlugins);
        }
        if (enableTwemoji) {
          editor.use(twemojiPlugins);
        } else {
          await editor.remove(twemojiPlugins);
        } */

        // await editor.create();
        // configure collab again after editor.create

        // setInspector(() => editor.inspect());
      };

      effect().catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
    });
  }, [
    //blockPlugins,
    //diagramPlugins,
    get,
    //gfmPlugins,
    //mathPlugins,
    //twemojiPlugins,
    loading,
    //enableGFM,
    //enableMath,
    //enableDiagram,
    //enableBlockHandle,
    //enableTwemoji,
    //setInspector,
  ]);

  return editorInfo;
};
