import { useTranslation } from "@dzangolab/react-i18n";
import { Tag } from "@dzangolab/react-ui";

const ReleasePageTitle = ({
  title,
  subtitle,
  isDraft,
}: {
  title: string;
  subtitle: string;
  isDraft?: boolean;
}) => {
  const { t } = useTranslation("release");

  return (
    <>
      <p>{subtitle}</p>
      <div className="title">
        <h1>{title}</h1>
        {isDraft && <Tag color="blue" label={t("release-state.draft")} />}
      </div>
    </>
  );
};

export default ReleasePageTitle;
