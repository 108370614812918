import baseApi, { TagsEnum } from "./base-api";

import type { Release, Workspace, WorkspaceCreateInput } from "core";

interface WorkspaceRelease {
  name: string;
  merged: boolean;
  default: boolean;
}

const workspacesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkspaces: build.query<Workspace[], void>({
      query: () => {
        return {
          url: "workspaces",
        };
      },
      providesTags: [TagsEnum.Workspaces],
    }),

    getWorkspace: build.query<Workspace, any>({
      query: (id: number) => {
        return {
          url: `workspaces/${id}`,
        };
      },
      transformResponse: (response: Workspace) => response,
      providesTags: [TagsEnum.Workspace],
    }),

    getWorkspaceBySecondaryIdentifier: build.query<Workspace, any>({
      query: (secondaryIdentifier: string) => {
        return {
          url: `workspaces/secondaryId/${secondaryIdentifier}`,
        };
      },
      transformResponse: (response: Workspace) => response,
      providesTags: [TagsEnum.Workspace],
    }),

    getWorkspaceReleases: build.query<
      Release[] | WorkspaceRelease[],
      { workspaceId: number }
    >({
      query: ({ workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/releases`,
        };
      },
      transformResponse: (response: Release[]) => response,
      providesTags: [TagsEnum.Workspace],
    }),

    createWorkspace: build.mutation<Workspace, WorkspaceCreateInput>({
      query: (data: WorkspaceCreateInput) => {
        let url = "workspaces";

        return {
          url: url,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.Workspaces],
    }),

    deleteWorkspace: build.mutation<Workspace, any>({
      query: (id: number) => {
        return {
          url: `/workspaces/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: (response: Workspace) => response,
      invalidatesTags: [TagsEnum.Workspaces],
    }),

    updateWorkspace: build.mutation<
      Workspace,
      { data: WorkspaceCreateInput; id: number }
    >({
      query: ({ data, id }) => {
        let url = `workspaces/${id}`;

        return {
          url: url,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.Workspaces, TagsEnum.Workspace],
    }),

    configureWorkspace: build.mutation<Workspace, { config: any; id: number }>({
      query: ({ config, id }) => {
        let url = `workspaces/${id}/configure`;

        return {
          url: url,
          method: "PUT",
          body: config,
        };
      },
      invalidatesTags: [TagsEnum.Workspaces, TagsEnum.Workspace],
    }),

    generateSourceCode: build.query<
      Workspace,
      { workspaceId: number; releaseId: number }
    >({
      query: ({ workspaceId, releaseId }) => {
        return {
          url: `workspaces/${workspaceId}/release/${releaseId}/generate`,
        };
      },
      transformResponse: (response: Workspace) => response,
      providesTags: [TagsEnum.Workspace],
    }),
  }),
});

export const {
  useLazyGetWorkspacesQuery,
  useGetWorkspaceQuery,
  useLazyGetWorkspaceQuery,
  useLazyGetWorkspaceBySecondaryIdentifierQuery,
  useCreateWorkspaceMutation,
  useDeleteWorkspaceMutation,
  useUpdateWorkspaceMutation,
  useConfigureWorkspaceMutation,
  useLazyGetWorkspaceReleasesQuery,
  useLazyGenerateSourceCodeQuery,
} = workspacesApi;
export default workspacesApi;
