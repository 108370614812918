import { Password, TextInput, useFormContext } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button, SubmitButton } from "@dzangolab/react-ui";
import { Repository } from "core";
import { useEffect } from "react";
import { toast } from "react-toastify";

import { FormFields } from "./RepositoryForm";
import { useLazyValidateCredentialsQuery } from "../../../redux/apis/repositories";
import { isFetchBaseQueryError } from "../../../redux/util";

interface Properties {
  handleCancel: () => void;
  isLoading: boolean;
  repository?: Repository;
  fieldErrors?: { [key: string]: string };
}

const RepositoryFormFields: React.FC<Properties> = ({
  handleCancel,
  isLoading,
  repository,
  fieldErrors,
}) => {
  const { t } = useTranslation("repositories");

  const {
    register,
    getFieldState,
    setError,
    formState: { errors },
    watch,
  } = useFormContext<FormFields>();

  const [url, username, accessToken] = watch([
    "url",
    "username",
    "accessToken",
  ]);

  const [validateCredentials, { data, error, isError, isFetching }] =
    useLazyValidateCredentialsQuery();

  const isDisabled = !url || !!errors.url || !accessToken || !username;

  const validateCredentialsHandler = () => {
    if (errors.url) return;

    validateCredentials({
      url,
      credential: {
        username,
        accessToken,
      },
    });
  };

  useEffect(() => {
    if (fieldErrors?.name) {
      setError("name", {
        type: "manual",
        message: t(`form.validations.name.${fieldErrors.name}`),
      });
    }

    if (fieldErrors?.url) {
      setError("url", {
        type: "manual",
        message: t(`form.validations.url.${fieldErrors.url}`),
      });
    }
  }, [fieldErrors, setError, t]);

  useEffect(() => {
    if (!isFetching && !error && data?.ok) {
      toast.success(t("messages.validCredentials"));
    }
  }, [data, error, isFetching, t]);

  useEffect(() => {
    if (isError && isFetchBaseQueryError(error)) {
      const errorMessage = error.data as {
        error?: string;
        requiredScopes?: string[];
      };

      const errorMessages: Record<string, string> = {
        AUTH_FAILURE: "messages.invalidCredential",
        FORBIDDEN_PERSONAL_ACCESS_TOKEN: "messages.forbiddenAccessToken",
        INSUFFICIENT_SCOPES: "messages.insufficientScopes",
        NO_PULL_PUSH_PERMISSION: "messages.noPullPushPermission",
        PERMISSION_DENIED: "messages.permissionDenied",
        REPO_NOT_FOUND: "messages.noRepositoryFound",
        TOKEN_EXPIRED: "messages.tokenExpired",
        UNAUTHORIZED_ACCESS: "messages.unauthorizedAccess",
        UNAUTHORIZED_ACCESS_GITHUB: "messages.unauthorizedAccessGithub",
      };

      const messageKey = errorMessages[errorMessage.error as string];

      if (errorMessage.error === "INSUFFICIENT_SCOPES") {
        toast.error(
          t(messageKey, {
            scopes: errorMessage.requiredScopes?.join(", ") || "",
          }),
        );
      } else if (messageKey) {
        toast.error(t(messageKey));
      } else {
        toast.error(t("form.messages.error.default"));
      }
    }
  }, [isError, error, t]);

  const getErrorMessage = (field: keyof FormFields): string => {
    return errors?.[field]?.message || "";
  };

  return (
    <>
      <TextInput
        label={t("form.fields.name")}
        name="name"
        errorMessage={getErrorMessage("name")}
      />
      <TextInput
        label={t("form.fields.url")}
        name="url"
        errorMessage={getErrorMessage("url")}
      />
      <TextInput
        label={t("form.fields.username")}
        name="username"
        errorMessage={getErrorMessage("username")}
      />
      <Password
        label={t("form.fields.accessToken")}
        name="accessToken"
        register={register}
        getFieldState={getFieldState}
      />
      <Button
        label={t("form.actions.validateCredentials")}
        type="button"
        loading={isFetching}
        onClick={validateCredentialsHandler}
        disabled={isDisabled}
        severity="secondary"
      />
      <SubmitButton
        label={repository ? t("form.actions.update") : t("form.actions.submit")}
        loading={isLoading}
      />
      <Button
        label={t("form.actions.cancel")}
        type="button"
        onClick={handleCancel}
        severity="secondary"
        variant="outlined"
      />
    </>
  );
};

export default RepositoryFormFields;
