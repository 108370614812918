import { Provider, NumberInput } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button } from "@dzangolab/react-ui";
import React from "react";
import { z } from "zod";

import "./tooltip.css";

interface Properties {
  onSave: (
    rows: number,
    columns: number,
    event?: React.MouseEvent<HTMLButtonElement>,
  ) => void;
}

interface FormSchema {
  rows: number;
  columns: number;
}

function useFormSchema() {
  let linkSchema = z.object({
    rows: z.number().max(10).min(2),
    columns: z.number().max(10).min(2),
  });

  return linkSchema;
}

export default function LinkTooltip({ onSave }: Properties) {
  const { t } = useTranslation("markdownEditor");
  const onSubmit = (formData: FormSchema) => {
    onSave(formData.rows, formData.columns);
  };

  return (
    <div className="input-wrapper">
      <Provider
        onSubmit={onSubmit}
        validationSchema={useFormSchema()}
        className="form-wrapper"
      >
        <NumberInput
          label={t("toolbar.tableTooltip.rows")}
          name={"rows"}
          type="number"
          placeholder={t("toolbar.tableTooltip.rows")}
        />
        <NumberInput
          label={t("toolbar.tableTooltip.columns")}
          name="columns"
          type="number"
          placeholder={t("toolbar.tableTooltip.columns")}
        />
        <Button type="submit" label={t("toolbar.linkTooltip.save")} />
      </Provider>
    </div>
  );
}
