interface Properties {
  title: string;
  subTitle: string;
  children: React.ReactNode;
}

export const SectionContainer: React.FC<Properties> = ({
  title,
  subTitle,
  children,
}) => {
  return (
    <section className="general-setting-section">
      <h2 className="general-setting-title">{title}</h2>
      <p className="general-setting-sub-title">{subTitle}</p>
      {children}
    </section>
  );
};

export default SectionContainer;
