import { useTranslation } from "@dzangolab/react-i18n";
import {
  NavGroupType,
  NavItemType,
  Sidebar,
  SidebarFooter,
  SidebarHeader,
} from "@dzangolab/react-layout";
import { LoadingPage } from "@dzangolab/react-ui";
import { UserEnabledSidebarHeaderLayout, useUser } from "@dzangolab/react-user";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { NavigationMenu } from "./components";
import WorkspacesLayoutTitle from "../components/common/WorkspacesLayoutTitle";
import { feature } from "../config";
import { ROUTES } from "../constants";
import { useCurrentWorkspace } from "../hooks/UseCurrentWorkspace";
import baseApi from "../redux/apis/base-api";
import { useLazyGetReleaseBySlugQuery } from "../redux/apis/release";
import { useLazyGetWorkspaceBySlugQuery } from "../redux/apis/workspaces";
import {
  resetSelectedWorkspaces,
  setSelectedWorkspace,
} from "../redux/SelectedWorkspacesSlice";
import { WebSocketProvider } from "../WebSocketContext";

const getMenuRoutes = () => {
  const releaseRoutes = [
    {
      key: "header.menu.overview",
      route: ROUTES.WORKSPACE_RELEASE,
      icon: "pi-briefcase",
    },
    ...(feature("documentation")
      ? [
          {
            key: "header.menu.docs",
            route: ROUTES.WORKSPACE_DOCS,
            icon: "pi-book",
          },
        ]
      : []),
  ];

  const workspaceRoutes = [
    {
      key: "header.menu.releases",
      route: ROUTES.WORKSPACE_RELEASES,
      icon: "pi-book",
    },
    {
      key: "header.menu.repositories",
      route: ROUTES.WORKSPACE_REPOSITORIES,
      icon: "pi-book",
    },
    {
      key: "header.menu.services",
      route: ROUTES.SERVICES,
      icon: "pi-book",
    },
    {
      key: "header.menu.glossary",
      route: ROUTES.WORKSPACE_GLOSSARY,
      icon: "pi-book",
    },
    {
      key: "header.menu.team",
      route: ROUTES.WORKSPACE_TEAM,
      icon: "pi-users",
    },
    {
      key: "header.menu.settings.label",
      route: ROUTES.WORKSPACE_SETTINGS_GENERAL,
      icon: "pi-cog",
      submenu: [
        {
          key: "header.menu.settings.submenu.general",
          route: ROUTES.WORKSPACE_SETTINGS_GENERAL,
        },
        {
          key: "header.menu.settings.submenu.glossary",
          route: ROUTES.WORKSPACE_SETTINGS_GLOSSARY,
        },
        {
          key: "header.menu.settings.submenu.releaseType",
          route: ROUTES.WORKSPACE_RELEASE_TYPE,
        },
        ...(feature("documentation")
          ? [
              {
                key: "header.menu.settings.submenu.docs",
                route: ROUTES.WORKSPACE_SETTINGS_DOCUMENTATION,
              },
            ]
          : []),
      ],
    },
  ];

  const menuRoutes = {
    release: releaseRoutes,
    workspace: workspaceRoutes,
  };

  return menuRoutes;
};

export const WorkspacesLayout = (): JSX.Element => {
  const { t } = useTranslation("app");
  const parameters = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { slug: workspaceSlug, releaseSlug } = parameters as {
    slug: string;
    releaseSlug: string;
  };
  const [
    getWorkspaceBySlug,
    { data: workspace, isFetching: isFetchingWorkspace, error: workspaceError },
  ] = useLazyGetWorkspaceBySlugQuery();

  const [getReleaseBySlug, { data: release, isFetching: isFetchingRelease }] =
    useLazyGetReleaseBySlugQuery();

  const { workspace: selectedWorkspace, release: selectedRelease } =
    useCurrentWorkspace();

  const { user } = useUser();

  const getNavigationMenu = () => {
    const menuRoutes = getMenuRoutes();
    const releaseRoutes = menuRoutes.release;
    const workspaceRoutes = menuRoutes.workspace;

    const releaseSpecificMenu = generateNavigationMenu(
      t,
      releaseRoutes,
      selectedWorkspace?.slug,
      selectedRelease?.slug,
    );

    const workspaceMenu = generateNavigationMenu(
      t,
      workspaceRoutes,
      selectedWorkspace?.slug,
      selectedRelease?.slug,
    );

    let menu = [{ id: "workspace-navigation-menu", menu: workspaceMenu }];

    if (selectedRelease) {
      menu.unshift({
        id: "release-navigation-menu",
        menu: releaseSpecificMenu,
      });
    }

    return menu;
  };

  useEffect(() => {
    if (workspaceSlug) {
      getWorkspaceBySlug(workspaceSlug);
    }
  }, [workspaceSlug, getWorkspaceBySlug]);

  useEffect(() => {
    if (
      !isFetchingWorkspace &&
      workspaceSlug &&
      workspaceError &&
      "status" in workspaceError &&
      workspaceError.status === 404
    ) {
      navigate(ROUTES.WORKSPACES);
    }
  }, [workspaceSlug, workspaceError, navigate]);

  useEffect(() => {
    if (workspace && workspace.id && releaseSlug) {
      getReleaseBySlug({
        workspaceId: workspace.id,
        releaseSlug,
      });
    }
  }, [workspace, releaseSlug, getReleaseBySlug]);

  useEffect(() => {
    if (workspace) {
      dispatch(setSelectedWorkspace({ workspace, release }));
    }
  }, [workspace, release, dispatch]);

  const navigationMenu = getNavigationMenu();
  const userMenu = generateUserMenu(t, !!user);

  const getCustomSidebar = () => {
    return (
      <Sidebar>
        <SidebarHeader />
        <NavigationMenu navigationMenu={user ? navigationMenu : []} />
        <SidebarFooter noLocaleSwitcher />
      </Sidebar>
    );
  };

  return (
    <WebSocketProvider>
      <UserEnabledSidebarHeaderLayout
        className="workspaces-layout"
        title={<WorkspacesLayoutTitle />}
        noLocaleSwitcher={false}
        children={
          isFetchingRelease || isFetchingWorkspace ? (
            <LoadingPage />
          ) : (
            <Outlet />
          )
        }
        userNavigationMenu={{
          id: "user-menu",
          label: t("user:userMenu.label"),
          menu: userMenu,
        }}
        customSidebar={getCustomSidebar()}
        displayNavIcons={true}
        onLogout={async () => handleLogout(dispatch)}
      />
    </WebSocketProvider>
  );
};

const generateNavigationMenu = (
  t: (key: string) => string,
  menuRoutes: {
    key: string;
    icon: string;
    route: string;
    submenu?: { key: string; route: string }[];
  }[],
  workspaceSlug: string,
  releaseSlug: string,
): Array<NavItemType | NavGroupType> => {
  const routes: Array<NavItemType | NavGroupType> = [];

  menuRoutes.forEach((route) => {
    let submenu: Array<NavItemType> = [];

    if (route.submenu) {
      route.submenu.forEach((subroute) => {
        submenu.push({
          ...subroute,
          route: subroute.route
            .replace(":slug", workspaceSlug)
            .replace(":releaseSlug", releaseSlug),
          label: t(subroute.key),
        });
      });
    }

    routes.push({
      ...route,
      ...(submenu.length > 0 && { submenu }),
      route: route.route
        .replace(":slug", workspaceSlug)
        .replace(":releaseSlug", releaseSlug),
      label: t(route.key),
      icon: `pi ${route.icon}`,
    });
  });

  return routes;
};

const generateUserMenu = (t: (key: string) => string, isUser?: boolean) => {
  if (!isUser) return [];

  return [
    {
      icon: "pi pi-lock",
      label: t("user:changePassword.title"),
      route: ROUTES.CHANGE_PASSWORD,
    },
    {
      icon: "pi pi-user",
      label: t("user:userMenu.profile"),
      route: ROUTES.PROFILE,
    },
  ];
};

const handleLogout = (dispatch: any) => {
  dispatch(resetSelectedWorkspaces());
  dispatch(baseApi.util.resetApiState());
};
