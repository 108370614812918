import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Page } from "@dzangolab/react-ui";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import GlossaryEntries from "./components/GlossaryEntries";
import GlossaryLocalePicker from "./components/GlossaryLocalePicker";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import {
  useLazyGetGlossaryQuery,
  useLazyGetWorkspaceGlossaryQuery,
} from "../../redux/apis/glossary";

const Glossary = () => {
  const [
    fetchWorkspaceGlossary,
    { data: workspaceGlossary, isFetching: isLoadingWorkspaceGlossary },
  ] = useLazyGetWorkspaceGlossaryQuery();

  const [fetchGlossary, { data: glossary, isFetching: isLoadingGlossary }] =
    useLazyGetGlossaryQuery();

  const navigate = useNavigate();
  const { t } = useTranslation("glossary");

  const [selectedLocale, setSelectedLocale] = useState<string | undefined>();

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const navigateToStartGlossary = useCallback(() => {
    if (currentWorkspace && currentWorkspace.slug) {
      navigate(`/workspaces/${currentWorkspace.slug}/glossary/start`);
    }
  }, [currentWorkspace, navigate]);

  const navigateToAddFirstEntry = useCallback(() => {
    if (currentWorkspace && currentWorkspace.slug && glossary) {
      navigate(
        `/workspaces/${currentWorkspace.slug}/glossary/${glossary.id}/add-first-term`,
      );
    }
  }, [currentWorkspace, glossary, navigate]);

  const navigateToCreateGlossaryEntry = useCallback(() => {
    if (currentWorkspace && currentWorkspace.slug && glossary) {
      navigate(
        `/workspaces/${currentWorkspace.slug}/glossary/${glossary.id}/entry/new`,
      );
    }
  }, [currentWorkspace, glossary, navigate]);

  const navigateToGlossarySettings = useCallback(() => {
    if (currentWorkspace && currentWorkspace.slug && glossary) {
      navigate(`/workspaces/${currentWorkspace.slug}/settings/glossary`);
    }
  }, [currentWorkspace, glossary, navigate]);

  useEffect(() => {
    if (currentWorkspace) {
      fetchWorkspaceGlossary(currentWorkspace.id).then((response: any) => {
        if (
          "error" in response &&
          response.error.status === 404 &&
          response.error.data.error === "ERROR_WORKSPACE_GLOSSARY_NOT_FOUND"
        ) {
          navigateToStartGlossary();
        }
      });
    }
  }, [currentWorkspace, fetchWorkspaceGlossary, navigateToStartGlossary]);

  useEffect(() => {
    if (workspaceGlossary && workspaceGlossary.glossaryId) {
      fetchGlossary(workspaceGlossary.glossaryId);
    }
  }, [fetchGlossary, workspaceGlossary]);

  useEffect(() => {
    if (glossary && !isLoadingGlossary) {
      if (glossary.entries.length === 0) {
        navigateToAddFirstEntry();
      }

      if (glossary.locales.length === 0) {
        navigateToGlossarySettings();
      }

      const mainLocale = glossary.locales.find((locale) => locale.mainLocale);

      if (mainLocale) {
        setSelectedLocale(mainLocale.localeCode);
      }
    }
  }, [
    glossary,
    isLoadingGlossary,
    navigateToAddFirstEntry,
    navigateToGlossarySettings,
  ]);

  return (
    <Page
      className="glossary-page"
      loading={isLoadingGlossary || isLoadingWorkspaceGlossary}
      title={t("title")}
      toolbar={
        <div className="glossary-toolbar">
          <div className="glossary-actions">
            <GlossaryLocalePicker
              changeLocale={setSelectedLocale}
              locales={
                glossary?.locales.map((locale) => locale.localeCode) || []
              }
              selectedLocale={selectedLocale}
            />
            <Button
              onClick={navigateToCreateGlossaryEntry}
              label={"New entry"}
            />
          </div>
          <Button
            onClick={navigateToGlossarySettings}
            iconLeft={"pi pi-cog"}
            variant="textOnly"
            className="glossary-settings-button"
          />
        </div>
      }
    >
      {currentWorkspace && glossary && glossary.entries && (
        <GlossaryEntries
          entries={glossary.entries}
          glossaryId={glossary.id}
          locale={selectedLocale ?? "en"}
          workspaceSlug={currentWorkspace.slug}
          glossaryLocales={glossary.locales.map((locale) => {
            return {
              label: locale.name,
              value: locale.localeCode,
            };
          })}
        />
      )}
    </Page>
  );
};

export default Glossary;
