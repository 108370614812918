import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Card, CardBody, CardHeader } from "@dzangolab/react-ui";
import React from "react";

interface Properties {
  handleDelete: () => void;
}
const WorkspaceDeleteCard: React.FC<Properties> = ({ handleDelete }) => {
  const { t } = useTranslation("settings");

  return (
    <Card outlined className="workspace-delete-card">
      <CardHeader>
        <h3 className="workspace-delete-card-header">
          {t("generalSettings.sections.advanced.deleteCard.title")}
        </h3>
      </CardHeader>
      <CardBody>
        <p className="workspace-delete-card-text">
          {t("generalSettings.sections.advanced.deleteCard.bodyText")}
        </p>
        <Button
          label={t(
            "generalSettings.sections.advanced.deleteCard.button.delete",
          )}
          severity="danger"
          type="button"
          onClick={handleDelete}
        />
      </CardBody>
    </Card>
  );
};

export default WorkspaceDeleteCard;
