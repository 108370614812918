import { useTranslation } from "@dzangolab/react-i18n";
import { Repository, Workspace } from "core";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface Properties {
  errorMessage?: string;
  initialValue?: number;
  onInitialization?: () => void;
  onSelect: (repository: Repository) => void;
  repositories: Repository[];
  workspace: Workspace;
}

const RepositoryPicker: React.FC<Properties> = (properties) => {
  const {
    errorMessage,
    initialValue,
    onInitialization,
    onSelect,
    repositories,
    workspace,
  } = properties;

  const { t } = useTranslation("repositoryPicker");

  const initialRepository = repositories.find(({ id }) => initialValue === id);
  const [repository, setRepository] = useState<Repository | undefined>(
    initialRepository,
  );

  const handleChange = (event: DropdownChangeEvent) => {
    setRepository(event.value);
    onSelect(event.value);
  };

  useEffect(() => {
    if (initialRepository) {
      onInitialization?.();
    }
  }, [initialRepository]);

  const panelFooterTemplate = () => {
    return (
      <div className="repository-picker-panel-footer">
        <Link to={`/workspaces/${workspace?.slug}/repositories/new`}>
          {t("dropdown.newRepository")}
        </Link>
      </div>
    );
  };

  return (
    <div className="repository-picker-wrapper">
      <Dropdown
        className="repository-picker"
        emptyMessage={t("dropdown.emptyMessage")}
        onChange={handleChange}
        optionLabel="name"
        options={repositories}
        panelFooterTemplate={panelFooterTemplate}
        placeholder={t("dropdown.placeholder")}
        value={repository}
      />
      {errorMessage && <span className="error-message">{errorMessage}</span>}
    </div>
  );
};

export default RepositoryPicker;
