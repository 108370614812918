import { Select, useFormContext, useWatch } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { SubmitButton } from "@dzangolab/react-ui";
import { SupportedLocales } from "core";
import React, { useEffect } from "react";

interface Properties {
  isSubmitting?: boolean;
  supportedLocales: SupportedLocales[];
}

const GlossaryFormFields: React.FC<Properties> = (properties) => {
  const { isSubmitting, supportedLocales } = properties;
  const [nonMainLocales, setNonMainLocales] = React.useState<
    SupportedLocales[]
  >([]);
  const { t } = useTranslation("glossary");

  const mainLocale = useWatch({ name: "mainLocale" }) as string | undefined;

  const { register } = useFormContext();

  useEffect(() => {
    if (mainLocale) {
      setNonMainLocales(
        supportedLocales.filter((locale) => locale.localeCode !== mainLocale),
      );
    }
  }, [mainLocale, supportedLocales]);

  return (
    <>
      <Select
        name="mainLocale"
        label={t("form.fields.mainLocale.label")}
        placeholder={t("form.fields.mainLocale.placeholder")}
        options={supportedLocales.map((locale) => {
          return {
            label: locale.name,
            value: locale.localeCode,
          };
        })}
      />

      {nonMainLocales.length > 0 && (
        <>
          <label htmlFor="supportedLocales">
            {t("form.fields.supportedLocales.label")}
          </label>
          {nonMainLocales.map((locale) => {
            return (
              <div
                className="checkbox-wrapper"
                key={`supportedLocales-${locale.localeCode}`}
              >
                <input
                  type="checkbox"
                  value={locale.localeCode}
                  id={`supportedLocales-${locale.localeCode}`}
                  {...register("supportedLocales")}
                />
                <label htmlFor={`supportedLocales-${locale.localeCode}`}>
                  {locale.name}
                </label>
              </div>
            );
          })}
        </>
      )}

      <SubmitButton disabled={isSubmitting} label="Save" />
    </>
  );
};

export default GlossaryFormFields;
