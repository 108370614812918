import { useTranslation } from "@dzangolab/react-i18n";
import {
  Button,
  ConfirmationModal,
  Page,
  SubPane,
  TabbedPanel,
} from "@dzangolab/react-ui";
import { Repository } from "core";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BranchRules } from "./components";
import config from "../../../config";
import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";
import { useLazyGetRuleTypesQuery } from "../../../redux/apis/branch-rules";
import { useLazyGetReleaseTypesQuery } from "../../../redux/apis/release";
import {
  useDeleteRepositoryMutation,
  useLazyGetRepositoryBranchRulesQuery,
  useLazyGetRepositoryQuery,
} from "../../../redux/apis/repositories";
import { GeneralInfo } from "../components/GeneralInfo";

interface SuccessResponse {
  data: {
    id: number;
  };
}

interface ErrorResponse {
  error: {
    data: {
      error: string;
    };
  };
}

const RepositoryDetail = () => {
  const { t } = useTranslation("repositoryDetails");
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [getReleaseTypes, { data: releaseTypes }] =
    useLazyGetReleaseTypesQuery();
  const [getRuleTypes, { data: ruleTypes }] = useLazyGetRuleTypesQuery();
  const [getRepository, { data: repository, isFetching: isRepositoryLoading }] =
    useLazyGetRepositoryQuery();
  const [
    fetchBranchRules,
    { data: branchRules, isFetching: isBranchRuleLoading },
  ] = useLazyGetRepositoryBranchRulesQuery();
  const [triggerDelete, { isLoading }] = useDeleteRepositoryMutation();

  useEffect(() => {
    const fetchData = async () => {
      if (currentWorkspace && id) {
        await Promise.all([
          getRepository({ workspaceId: currentWorkspace.id, id: Number(id) }),
          getReleaseTypes({ workspaceId: currentWorkspace.id }),
          getRuleTypes({}),
        ]);
      }

      if (currentWorkspace && repository) {
        fetchBranchRules({
          workspaceId: currentWorkspace.id,
          id: repository.id,
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkspace, id, repository]);

  const branchRule = () => {
    return (
      <SubPane title={t("title.rules")}>
        <BranchRules
          branchRules={branchRules}
          isLoading={isBranchRuleLoading}
          releaseTypes={releaseTypes}
          repositoryId={repository?.id!}
          ruleTypes={ruleTypes}
          workspace={currentWorkspace}
        />
      </SubPane>
    );
  };

  const handleEdit = () => {
    navigate(`/workspaces/${currentWorkspace.slug}/repositories/${id}/edit`, {
      state: repository,
    });
  };

  const deleteHandler = async () => {
    if (!repository) return;

    const { id, workspaceId } = repository;

    let response = (await triggerDelete({
      workspaceId,
      repositoryId: id,
    })) as SuccessResponse | ErrorResponse;

    if ("error" in response) {
      const errorMessage =
        response.error.data.error === "ERROR_REPOSITORY_IN_USE"
          ? t("messages.delete.repositoryInUse")
          : t("messages.delete.error");

      toast.error(errorMessage);
    } else {
      navigate(`/workspaces/${currentWorkspace.slug}/repositories`);

      toast.success(t("messages.delete.success"));
    }

    setShowDeleteConfirmation(false);
  };

  return (
    <Page
      title={repository?.name}
      className="repository-rules-page"
      loading={isRepositoryLoading}
      toolbar={
        <>
          <Button
            iconLeft={"pi pi-trash"}
            severity="danger"
            onClick={() => setShowDeleteConfirmation(true)}
          />
          <Button iconLeft={"pi pi-pen-to-square"} onClick={handleEdit} />
        </>
      }
    >
      <TabbedPanel
        id="repository-detail-general"
        persistState={isBranchRuleLoading && isRepositoryLoading ? true : false}
      >
        <SubPane title={t("title.general")}>
          <GeneralInfo repository={repository as Repository} />
        </SubPane>

        {config.features.branchFilter ? branchRule() : <></>}
      </TabbedPanel>

      <ConfirmationModal
        accept={deleteHandler}
        header={t("confirmation.header")}
        message={t("confirmation.message", {
          repositoryName: repository?.name,
        })}
        acceptButtonOptions={{ loading: isLoading, severity: "danger" }}
        visible={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
      />
    </Page>
  );
};

export default RepositoryDetail;
