import { Page } from "@dzangolab/react-ui";
import { ReleaseApp } from "core";
import { useEffect, useState } from "react";

import { Toolbar } from "./components";
import AddReleaseAppDialog from "./components/AddReleaseAppDialog";
import LinkReleaseServiceDialog from "./components/LinkReleaseServiceDialog";
import ReleaseGraph from "./components/ReleaseGraph";
import ReleasePageTitle from "../../components/common/ReleasePageTitle";
import { RELEASE_SECTIONS } from "../../constants";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { useLazyGetReleaseStatusesQuery } from "../../redux/apis/release";
import { useLazyGetServicesQuery } from "../../redux/apis/services";

const generatePageTitleAndSubtitle = (
  releaseName: string = "",
  releaseType: string | undefined = "",
): { title: string; subtitle: string } => {
  const lastSlashIndex = releaseName.lastIndexOf("/");
  let title = "";
  let subtitle = "";

  releaseType = (typeof releaseType === "string" && releaseType) || "";

  if (lastSlashIndex > 0) {
    title = releaseName.slice(lastSlashIndex + 1);
    subtitle = `${releaseType} — ${releaseName.slice(0, lastSlashIndex + 1)}`;
  } else {
    title = releaseName;
    subtitle = releaseType;
  }

  return { title, subtitle };
};

const Overview: React.FC = () => {
  const [selectedReleaseApp, setSelectedReleaseApp] = useState<ReleaseApp>();
  const [addReleaseServiceDialogVisible, setAddReleaseServiceDialogVisible] =
    useState(false);
  const [addReleaseAppDialogVisible, setAddReleaseAppDialogVisible] =
    useState(false);
  const [moduleType, setModuleType] = useState("");

  const [getServices, { data: workspaceServices, isLoading: loadingServices }] =
    useLazyGetServicesQuery();
  const [fetchReleaseStatuses, { data: releaseStatuses }] =
    useLazyGetReleaseStatusesQuery();

  const { release: currentRelease, workspace: currentWorkspace } =
    useCurrentWorkspace();

  const { subtitle, title } = generatePageTitleAndSubtitle(
    currentRelease?.name,
    currentRelease?.type?.slug,
  );

  useEffect(() => {
    fetchReleaseStatuses();

    if (currentWorkspace) {
      getServices({ workspaceId: currentWorkspace.id });
    }
  }, [currentWorkspace, fetchReleaseStatuses, getServices]);

  const addReleaseModuleHandler = (type: string, app?: ReleaseApp) => {
    setModuleType(type);

    if (app) {
      setSelectedReleaseApp(app);
    }

    if (type === RELEASE_SECTIONS.SERVICES) {
      setAddReleaseServiceDialogVisible(true);
    } else {
      setAddReleaseAppDialogVisible(true);
    }
  };

  const hideAddReleaseAppDialog = () => {
    setAddReleaseAppDialogVisible(false);
    setSelectedReleaseApp(undefined);
  };

  const renderReleaseGraph = () => {
    if (currentRelease && Object.hasOwnProperty.call(currentRelease, "apps")) {
      return (
        <ReleaseGraph
          release={currentRelease}
          onAddReleaseModule={addReleaseModuleHandler}
        />
      );
    }
  };

  return (
    <Page
      className={"overview-page graph"}
      title={
        <ReleasePageTitle
          title={title}
          subtitle={subtitle}
          isDraft={currentRelease && currentRelease.isDraft}
        />
      }
      toolbar={
        <Toolbar
          releaseStatuses={releaseStatuses}
          isDraft={currentRelease && currentRelease.isDraft}
        />
      }
      loading={
        loadingServices ||
        (currentWorkspace?.isCodeGenerated ? !currentRelease : false)
      }
    >
      {renderReleaseGraph()}
      <LinkReleaseServiceDialog
        visible={addReleaseServiceDialogVisible}
        onHide={() => setAddReleaseServiceDialogVisible(false)}
        release={currentRelease}
        workspaceServices={workspaceServices ?? []}
      />
      {moduleType !== "" && (
        <AddReleaseAppDialog
          releaseApp={selectedReleaseApp}
          visible={addReleaseAppDialogVisible}
          onHide={hideAddReleaseAppDialog}
          moduleType={moduleType}
        />
      )}
    </Page>
  );
};

export default Overview;
