import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { ReleaseCreateInput, ReleaseType } from "core";
import React from "react";
import { z } from "zod";

import ReleaseFormFields from "./ReleaseFormFields";

interface Properties {
  actionLabel?: string;
  fieldErrors?: Record<string, string>;
  isSubmitting?: boolean;
  release?: ReleaseCreateInput & { type: ReleaseType };
  releaseTypes?: ReleaseType[];
  onCancel: () => void;
  onSubmit: (data: ReleaseCreateInput) => void;
}

const ReleaseForm: React.FC<Properties> = (properties) => {
  const {
    actionLabel,
    fieldErrors,
    isSubmitting,
    release,
    onCancel,
    onSubmit,
  } = properties;
  const { t } = useTranslation("release");

  const validationSchema = z.object({
    description: z.string().nullable(),
    isMultitenant: z.boolean(),
    name: z.string().min(1, t("form.validations.name.required")),
    typeId: z.number().min(1, t("form.validations.type.required")),
  });

  const defaultValues = {
    description: release?.description ?? null,
    isMultitenant: release?.isMultitenant ?? false,
    name: release?.name ?? "",
    typeId: release?.type.id ?? "",
  };

  return (
    <Provider
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <ReleaseFormFields
        actionLabel={actionLabel}
        fieldErrors={fieldErrors}
        handleCancel={onCancel}
        isLoading={isSubmitting ?? false}
        releaseTypes={properties.releaseTypes}
      />
    </Provider>
  );
};

export default ReleaseForm;
