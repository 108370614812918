import { useTranslation } from "@dzangolab/react-i18n";
import { Page } from "@dzangolab/react-ui";
import { useState } from "react";

import BaseWorkspaceForm from "../../components/Form/WorkspaceForm";
import SectionContainer from "../../components/SectionContainer";
import WorkspaceDeleteCard from "../../components/WorkspaceDeleteCard";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import DeleteConfirmationModal from "./DeleteSettings/components/DeleteConfirmationModal";

const GeneralSettings = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { t } = useTranslation("settings");
  const { workspace: currentWorkspace } = useCurrentWorkspace();

  return (
    <Page className="settings-page" title={t("generalSettings.title")}>
      <SectionContainer
        title={t("generalSettings.sections.edit.title")}
        subTitle={t("generalSettings.sections.edit.subTitle")}
      >
        <BaseWorkspaceForm workspace={currentWorkspace} />
      </SectionContainer>

      <SectionContainer
        title={t("generalSettings.sections.advanced.title")}
        subTitle={t("generalSettings.sections.advanced.subTitle")}
      >
        <WorkspaceDeleteCard
          handleDelete={() => setShowConfirmationModal(true)}
        />
      </SectionContainer>

      {currentWorkspace && (
        <DeleteConfirmationModal
          visible={showConfirmationModal}
          onHide={() => setShowConfirmationModal(false)}
          workspace={currentWorkspace}
        />
      )}
    </Page>
  );
};

export default GeneralSettings;
