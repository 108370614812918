import { useTranslation } from "@dzangolab/react-i18n";
import {
  Button,
  TDataTable as DataTable,
  Page,
  TableColumnDefinition,
} from "@dzangolab/react-ui";
import {
  Release,
  ReleaseApp,
  WORKSPACE_RELEASE_STATUS,
  formatDate,
} from "core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ReleaseDuplicateModal, ReleaseFormModal } from "./components";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import {
  useDeleteReleaseMutation,
  useLazyGetReleaseTypesQuery,
  useLazySetupAndBuildReleaseQuery,
  useMarkReleaseAsReadyMutation,
} from "../../redux/apis/release";
import { useLazyGetWorkspaceReleasesQuery } from "../../redux/apis/workspaces";
import { resetSelectedRelease } from "../../redux/SelectedWorkspacesSlice";

const WorkspaceReleases = () => {
  const { t } = useTranslation("release");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { workspace: currentWorkspace, release } = useCurrentWorkspace();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [releaseToDuplicate, setReleaseToDuplicate] = useState<any>();

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const [markReleaseAsReady] = useMarkReleaseAsReadyMutation();
  const [fetchReleases, { data: releases, isFetching: isLoadingReleases }] =
    useLazyGetWorkspaceReleasesQuery();
  const [setupBuildRelease] = useLazySetupAndBuildReleaseQuery();
  const [fetchReleaseTypes, { data: releaseTypes }] =
    useLazyGetReleaseTypesQuery();
  const [deleteRelease] = useDeleteReleaseMutation();

  useEffect(() => {
    if (currentWorkspace) {
      fetchReleases({ workspaceId: currentWorkspace.id });
      fetchReleaseTypes({ workspaceId: currentWorkspace.id });
    }
  }, [currentWorkspace, fetchReleases, fetchReleaseTypes]);

  const selectRelease = (release: any) => {
    if (release && currentWorkspace) {
      navigate(`/workspaces/${currentWorkspace.slug}/releases/${release.slug}`);
    }
  };

  const getReleaseState = (release: any) => {
    const apps = release?.apps ?? [];

    const allInState = (state: string) =>
      apps.every((app: ReleaseApp) => app.status === state);

    const anyInState = (state: string) =>
      apps.some((app: ReleaseApp) => app.status === state);

    if (apps.some((app: ReleaseApp) => !app.status)) {
      return { disablePlay: true, disableStop: true };
    }

    const disablePlay =
      allInState(WORKSPACE_RELEASE_STATUS.BUILDING) ||
      allInState(WORKSPACE_RELEASE_STATUS.RUNNING);
    const disableStop =
      allInState(WORKSPACE_RELEASE_STATUS.BUILDING) ||
      anyInState(WORKSPACE_RELEASE_STATUS.READY) ||
      anyInState(WORKSPACE_RELEASE_STATUS.ERROR) ||
      anyInState(WORKSPACE_RELEASE_STATUS.STOPPED);

    return { disablePlay, disableStop };
  };

  const getReleaseStateLabel = (release: Release) => {
    if (release.isDraft) {
      return t("release-state.draft");
    }

    if (release.closedAt) {
      return t("release-state.closed");
    }

    const { disablePlay, disableStop } = getReleaseState(release);

    if (disablePlay && disableStop) {
      return "";
    }

    if (disablePlay) {
      return WORKSPACE_RELEASE_STATUS.RUNNING;
    }

    if (disableStop) {
      return WORKSPACE_RELEASE_STATUS.READY;
    }

    return "";
  };

  const setupAndBuildActionHandler = async (selectedRelease: any) => {
    if (currentWorkspace && selectedRelease) {
      const response = await setupBuildRelease({
        workspaceId: currentWorkspace?.id,
        releaseId: selectedRelease.id,
      });

      if (response.error) {
        toast.error(t("messages.error.default"));

        return;
      }
    }
  };

  const deleteReleaseActionhandler = async (selectedRelease: Release) => {
    if (currentWorkspace && selectedRelease) {
      const response = await deleteRelease(selectedRelease.id);

      if (response.data) {
        toast.success(t("table.delete.message.success"));

        if (release && release.id === selectedRelease.id) {
          dispatch(resetSelectedRelease({ workspace: currentWorkspace }));
        }

        fetchReleases({ workspaceId: currentWorkspace.id });
      } else if (response.error) {
        toast.error(t("table.delete.message.error"));
      }
    }
  };

  const onReleaseMarkedAsReady = async (releaseId: number) => {
    if (currentWorkspace) {
      const response = await markReleaseAsReady({
        releaseId,
        workspaceId: currentWorkspace.id,
      });

      if (response.error) {
        toast.error(t("mark-as-ready.message.error"));

        return;
      }

      fetchReleases({ workspaceId: currentWorkspace.id });

      toast.success(t("mark-as-ready.message.success"));
    }
  };

  const renderToolbar = () => {
    return (
      <Button onClick={openModal}>
        <i className="pi pi-plus"></i>
      </Button>
    );
  };

  const columns: Array<TableColumnDefinition<any>> = [
    {
      accessorKey: "name",
      header: t("table.columns.name"),
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return (
          <Link
            className="release-name-link"
            to={`/workspaces/${currentWorkspace.slug}/releases/${original.slug}`}
            onClick={(event_) => {
              event_.preventDefault();
              selectRelease(original);
            }}
          >
            {original.name}
          </Link>
        );
      },
    },
    {
      accessorKey: "type",
      header: t("table.columns.type"),
      width: "10rem",
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return original.type?.name ?? "";
      },
    },
    {
      accessorKey: "status",
      accessorFn: (row) => row.status ?? undefined,
      header: t("table.columns.status"),
      width: "10rem",
      enableSorting: true,
      sortUndefined: "last",
      sortingFn: (rowA, rowB) => {
        const rowAStatus = rowA.original.status?.i18n[0].name || "";
        const rowBStatus = rowB.original.status?.i18n[0].name || "";

        return rowAStatus.localeCompare(rowBStatus);
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return original.status?.i18n[0].name ?? "";
      },
    },
    {
      accessorKey: "createdAt",
      header: t("table.columns.createdAt"),
      width: "10rem",
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return formatDate(original.createdAt);
      },
    },
    {
      accessorKey: "state",
      header: t("table.columns.state"),
      width: "10rem",
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        const rowAState = getReleaseStateLabel(rowA.original) || "";
        const rowBState = getReleaseStateLabel(rowB.original) || "";

        return rowAState.localeCompare(rowBState);
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return getReleaseStateLabel(original);
      },
    },
  ];

  return (
    <Page
      title={t("releases")}
      loading={isLoadingReleases}
      toolbar={renderToolbar()}
    >
      {releases && (
        <DataTable
          columns={columns}
          className="releases-table"
          isLoading={isLoadingReleases}
          data={releases}
          emptyTableMessage={t("table.empty")}
          initialSorting={[
            {
              id: "createdAt",
              desc: true,
            },
          ]}
          dataActionsMenu={(data) => {
            return {
              actions: [
                {
                  label: t("table.actions.view"),
                  onClick: (rowData: any) => {
                    selectRelease(rowData);
                  },
                },
                {
                  label: t("table.actions.duplicate"),
                  onClick: (rowData: any) => {
                    setReleaseToDuplicate(rowData);
                  },
                },
                {
                  confirmationOptions: {
                    message: t("table.mark-as-ready.message"),
                    header: t("table.mark-as-ready.header"),
                  },
                  display: (data) => {
                    return data.isDraft;
                  },
                  label: t("table.actions.mark-as-ready"),
                  onClick: (rowData: any) => {
                    onReleaseMarkedAsReady(rowData.id);
                  },
                  requireConfirmationModal: true,
                },
                {
                  className: "table-action-warning",
                  confirmationOptions: {
                    message: t("table.force-setup.message", {
                      releaseName: data.name,
                    }),
                    header: t("table.force-setup.header", {
                      releaseName: data.name,
                    }),
                  },
                  display: (data) => {
                    return !data.isDraft;
                  },
                  disabled: (data) => {
                    return !currentWorkspace.isCodeGenerated || data.closedAt;
                  },
                  label: t("table.actions.force-setup"),
                  requireConfirmationModal: true,
                  onClick: (rowData: any) => {
                    setupAndBuildActionHandler(rowData);
                  },
                },
                {
                  className: "table-action-danger",
                  confirmationOptions: {
                    message: t("table.delete.message.confirm", {
                      release: data.name,
                    }),
                    header: t("table.delete.header"),
                  },
                  label: t("table.actions.delete"),
                  requireConfirmationModal: true,
                  onClick: (rowData: Release) => {
                    deleteReleaseActionhandler(rowData);
                  },
                },
              ],
            };
          }}
        />
      )}
      <ReleaseFormModal
        onHide={closeModal}
        visible={isModalVisible}
        releaseTypes={releaseTypes}
      />
      <ReleaseDuplicateModal
        onHide={() => {
          setReleaseToDuplicate(null);
        }}
        release={releaseToDuplicate}
        visible={!!releaseToDuplicate}
        onDuplicate={() => {
          if (currentWorkspace) {
            fetchReleases({ workspaceId: currentWorkspace.id });
          }
        }}
        releaseTypes={releaseTypes}
      />
    </Page>
  );
};

export default WorkspaceReleases;
