import { ReleaseApp, WORKSPACE_RELEASE_STATUS } from "core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useLazyGetReleaseBySlugQuery } from "../redux/apis/release";
import { useLazyGetWorkspaceBySlugQuery } from "../redux/apis/workspaces";
import { setWorkspaceAndRelease } from "../redux/SelectedWorkspacesSlice";
import { RootState } from "../redux/store";

export const useCurrentWorkspace = () => {
  const parameters = useParams();
  const { slug: workspaceSlug, releaseSlug } = parameters;
  const [fetchWorkspaceBySlug] = useLazyGetWorkspaceBySlugQuery();
  const [fetchReleaseBySlug] = useLazyGetReleaseBySlugQuery();
  const [currentWorkspace, setCurrentWorkspace] = useState<any>();
  const [currentRelease, setCurrentRelease] = useState<any>();

  const workspaces = useSelector(
    (state: RootState) => state.selectedWorkspaces.workspaces,
  );

  useEffect(() => {
    if (workspaces && workspaceSlug) {
      const activeWorkspace = workspaces[workspaceSlug];

      if (activeWorkspace) {
        setCurrentWorkspace(activeWorkspace.workspace);
        setCurrentRelease(activeWorkspace.selectedRelease);
      }
    }
  }, [releaseSlug, workspaceSlug, workspaces]);

  const refetchWorkspaceAndRelease = async () => {
    if (workspaceSlug) {
      const releaseSlug =
        parameters.releaseSlug ??
        workspaces[workspaceSlug]?.selectedRelease?.slug;

      if (currentWorkspace && releaseSlug) {
        const response = await Promise.all([
          fetchWorkspaceBySlug(currentWorkspace.slug),
          fetchReleaseBySlug({
            workspaceId: currentWorkspace.id,
            releaseSlug,
          }),
        ]);

        if ("error" in response[0] || "error" in response[1]) {
          return;
        }

        setWorkspaceAndRelease({
          workspaceSlug: currentWorkspace.slug,
          workspace: response[0].data,
          release: response[1].data,
        });

        setCurrentWorkspace(response[0].data);
        setCurrentRelease(response[1].data);
      }
    }
  };

  const getReleaseActionStates = () => {
    const apps = currentRelease?.apps ?? [];

    // Determine the overall status based on the app statuses
    const allBuilding = apps.every(
      (app: ReleaseApp) => app.status === WORKSPACE_RELEASE_STATUS.BUILDING,
    );
    const allRunning = apps.every(
      (app: ReleaseApp) => app.status === WORKSPACE_RELEASE_STATUS.RUNNING,
    );
    const anyReady = apps.some(
      (app: ReleaseApp) => app.status === WORKSPACE_RELEASE_STATUS.READY,
    );
    const anyStop = apps.some(
      (app: ReleaseApp) => app.status === WORKSPACE_RELEASE_STATUS.STOPPED,
    );
    const anyError = apps.some(
      (app: ReleaseApp) => app.status === WORKSPACE_RELEASE_STATUS.ERROR,
    );

    // Check if any app status is null or an empty string
    const anyInvalidStatus = apps.some((app: ReleaseApp) => !app.status);

    const disablePlay = allBuilding || allRunning;
    const disableStop =
      allBuilding || anyReady || anyError || anyStop || anyInvalidStatus;

    const isProcessing = apps.some(
      (app: ReleaseApp) => app.status === WORKSPACE_RELEASE_STATUS.STOPPING,
    );

    return { disablePlay, disableStop, isProcessing };
  };

  return {
    workspace: currentWorkspace,
    release: currentRelease,
    getReleaseActionStates,
    refetchWorkspaceAndRelease,
  };
};
