import { useTranslation } from "@dzangolab/react-i18n";
import { UserEnabledSidebarHeaderLayout } from "@dzangolab/react-user";
import { Outlet } from "react-router-dom";

import { ROUTES } from "../constants";

export const PublicLayout = (): JSX.Element => {
  const { t } = useTranslation("app");

  const authNavigationMenu = generateAuthNavigationMenu(t);

  return (
    <UserEnabledSidebarHeaderLayout
      userMenuLocation="sidebar"
      className="public-layout"
      noLocaleSwitcher={false}
      authNavigationMenu={{ menu: authNavigationMenu }}
      children={<Outlet />}
      displayNavIcons={true}
    />
  );
};

const generateAuthNavigationMenu = (t: (key: string) => string) => [
  {
    icon: "pi pi-lock",
    label: t("user:menu.signin"),
    route: ROUTES.LOGIN,
  },
];
