import {
  Email,
  FormActions,
  Select,
  useFormContext,
} from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";

const RELEASE_ROLES = {
  ASSIGNEE: 1,
  REVIEWER: 2,
};

interface IProperties {
  isLoading: boolean;
  onCancel: () => void;
}

const InvitationFormFields: React.FC<IProperties> = ({
  isLoading,
  onCancel,
}) => {
  const { t } = useTranslation("workspaceTeam");
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Email
        name="email"
        label={t("invite-user.form.fields.email.label")}
        required={true}
        placeholder={t("invite-user.form.fields.email.placeholder")}
      />
      <Select
        name="defaultReleaseRoleId"
        placeholder={t(
          "invite-user.form.fields.default-release-role.placeholder",
        )}
        label={t("invite-user.form.fields.default-release-role.label")}
        options={Object.entries(RELEASE_ROLES).map(([key, value]) => ({
          label: key,
          value: value,
        }))}
      />
      <FormActions
        actions={[
          {
            id: "cancel",
            label: t("invite-user.form.actions.cancel"),
            type: "button",
            onClick: onCancel,
          },
          {
            id: "submit",
            label: t("invite-user.form.actions.submit"),
            loading: isLoading,
            disabled: Object.keys(errors).length > 0,
          },
        ]}
      />
    </>
  );
};

export default InvitationFormFields;
