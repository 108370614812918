// Sentry initialization should be imported first!
import "./sentry";

import { ConfigProvider } from "@dzangolab/react-config";
import i18n from "@dzangolab/react-i18n";
import { UserWrapper } from "@dzangolab/react-user";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import { App } from "./App";
import config, { userConfig } from "./config";
import { store } from "./redux/store";

// react-toastify
import "react-toastify/dist/ReactToastify.css";

// PrimeReact CSS
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import "@dzangolab/react-layout/dist/DzangolabReactLayout.css";
import "@dzangolab/react-ui/dist/DzangolabReactUI.css";
import "@dzangolab/react-form/dist/DzangolabReactForm.css";
import "@dzangolab/react-user/dist/DzangolabReactUser.css";

// import "ui/dist/12degUI.css";
import "./assets/css/style.css";

i18n(config.i18n);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ConfigProvider appConfig={config}>
    <Provider store={store}>
      <React.Suspense>
        <UserWrapper config={userConfig}>
          <App />
        </UserWrapper>
      </React.Suspense>
    </Provider>
  </ConfigProvider>,
);
