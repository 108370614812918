import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

import config from "../../config";

export enum TagsEnum {
  BranchRules = "branchRules",
  Document = "document",
  Documents = "documents",
  DocumentTemplate = "documentTemplate",
  DocumentTemplates = "documentTemplates",
  DocumentTypes = "documentTypes",
  DocumentType = "documentType",
  Glossaries = "glossaries",
  Glossary = "glossary",
  GlossaryEntries = "glossaryEntries",
  GlossaryEntry = "glossaryEntry",
  SupportedLocales = "supportedLocales",
  Releases = "releases",
  Release = "release",
  ReleaseStatuses = "releaseStatuses",
  ReleaseStatus = "releaseStatus",
  ReleaseTypes = "releaseTypes",
  Repositories = "repositories",
  Services = "services",
  Service = "service",
  Workspaces = "workspaces",
  Workspace = "workspace",
  WorkspaceGlossaries = "workspaceGlossaries",
  WorkspaceGlossary = "workspaceGlossary",
  WorkspaceUsers = "workspaceUsers",
}

/**
 * maxRetries: 5 is the default, and can be omitted. Shown for documentation purposes.
 * https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-retries
 *
 * To override maxRetires for each query
 * getPost: build.query<PostsResponse, string>({
      query: (id) => ({ url: `post/${id}` }),
      extraOptions: { maxRetries: 8 }, // You can override the retry behavior on each endpoint
    }),
 */
const staggeredBaseQuery = retry(
  fetchBaseQuery({ baseUrl: config.apiBaseUrl }),
  {
    maxRetries: 0,
  },
);

const baseApi = createApi({
  baseQuery: staggeredBaseQuery,
  keepUnusedDataFor: 30,
  tagTypes: Object.keys(TagsEnum),
  endpoints: (build) => ({}),
});

export default baseApi;
