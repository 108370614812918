import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Data } from "@dzangolab/react-ui";
import { Repository } from "core";
import React, { useState } from "react";
import { toast } from "react-toastify";

interface Properties {
  repository: Repository;
}

export const GeneralInfo: React.FC<Properties> = ({ repository }) => {
  const { t } = useTranslation("repositoryDetails");
  const [showToken, setShowToken] = useState(false);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);

    toast.success(t(`messages.success.copyRepoUrl`));
  };

  const maskToken = (token: string) => {
    return token.replace(/./g, "*");
  };

  return (
    <div className="repository-detail-general">
      <div className="data-button-container">
        <Data label={t(`info.labels.url`)} value={repository?.url} />
        <Button
          variant="textOnly"
          iconRight="pi pi-copy"
          size="small"
          onClick={() => handleCopy(repository?.url || "")}
        >
          {t(`info.labels.copy`)}
        </Button>
      </div>

      {repository?.credential?.username && (
        <Data
          label={t(`info.labels.username`)}
          value={repository?.credential.username}
        />
      )}

      {repository?.credential?.accessToken && (
        <div className="data-button-container">
          <Data
            label={t(`info.labels.accessToken`)}
            value={
              showToken
                ? repository?.credential.accessToken
                : maskToken(repository?.credential.accessToken || "")
            }
          />
          <Button
            variant="textOnly"
            iconRight={showToken ? "pi pi-eye-slash" : "pi pi-eye"}
            size="small"
            onClick={() => setShowToken(!showToken)}
          >
            {showToken ? t(`info.labels.hide`) : t(`info.labels.show`)}
          </Button>
        </div>
      )}
    </div>
  );
};
