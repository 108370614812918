import {
  NumberInput,
  Select,
  TextInput,
  Textarea,
  useFieldArray,
  useFormContext,
  useWatch,
} from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button, SubmitButton } from "@dzangolab/react-ui";
import { ReleaseApp, ReleaseAppCreateInput, Repository, Workspace } from "core";
import { useEffect } from "react";

import { EnvironmentVariablesInput } from "../../../../components";
import BranchPicker from "../../../../components/BranchPicker";
import RepositoryPicker from "../../../../components/RepositoryPicker";
import {
  useLazyGetRepositoriesQuery,
  useLazyGetRepositoryBranchesQuery,
} from "../../../../redux/apis/repositories";

interface Properties {
  releaseApp?: ReleaseApp;
  workspace: Workspace;
  handleCancel: () => void;
  handleDelete: () => void;
  isLoading: boolean;
}

const appTypes = [
  {
    label: "Apps",
    value: "apps",
  },
  {
    label: "Apis",
    value: "apis",
  },
];

const AddReleaseAppFormFields: React.FC<Properties> = (properties) => {
  const { handleCancel, handleDelete, isLoading, releaseApp, workspace } =
    properties;
  const { t } = useTranslation("appOverview");

  const [fetchRepositories, { data: repositories, isFetching }] =
    useLazyGetRepositoriesQuery();

  const [
    fetchRepositoryBranches,
    { data: branches, isFetching: isFetchingBranches },
  ] = useLazyGetRepositoryBranchesQuery();

  useEffect(() => {
    if (workspace) {
      fetchRepositories({ workspaceId: workspace.id });
    }
  }, [workspace, fetchRepositories]);

  const handleRepositorySelect = (selectedRepository: Repository) => {
    setValue("repositoryId", selectedRepository.id, { shouldValidate: true });
    setValue("branch", undefined);

    fetchRepositoryBranches({
      workspaceId: selectedRepository.workspaceId,
      id: selectedRepository.id,
    });
  };

  const {
    setValue,
    getFieldState,
    formState: { errors },
    register,
  } = useFormContext<ReleaseAppCreateInput>();

  const { fields, append, remove } = useFieldArray({
    name: "envVars",
  });
  const watchName: string = useWatch({ name: "name" });
  const watchBranch: string = useWatch({ name: "branch" });

  const getErrorMessage = (field: keyof ReleaseAppCreateInput): string => {
    return (errors[field]?.message as string) || "";
  };

  useEffect(() => {
    const { isDirty } = getFieldState("slug");

    if (!isDirty) {
      const generatedSlug = watchName
        ?.trim()
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^[-~!@#$%^&*()]+/, "")
        .replace(/[-~!@#$%^&*()]+$/, "");

      setValue("slug", generatedSlug);
    }
  }, [getFieldState, setValue, watchName]);

  const onInitialRepositorySelect = () => {
    if (releaseApp) {
      fetchRepositoryBranches({
        workspaceId: workspace.id,
        id: releaseApp.repositoryId,
      });

      setValue("branch", releaseApp.branch, { shouldValidate: true });
    }
  };

  return (
    <>
      <h3>{t("release-app.add.form.sections.moduleDetails")}</h3>
      <TextInput
        label={t("release-app.add.form.fields.name")}
        name="name"
        errorMessage={getErrorMessage("name")}
      />

      <Textarea
        label={t("release-app.add.form.fields.description")}
        name="description"
        errorMessage={getErrorMessage("description")}
      />

      {releaseApp && (
        <Select
          options={appTypes}
          name="type"
          label={t("release-app.add.form.fields.type")}
        />
      )}

      {!releaseApp && (
        <TextInput
          label={t("release-app.add.form.fields.slug")}
          name="slug"
          errorMessage={getErrorMessage("slug")}
        />
      )}

      <div className="flex-group">
        <div className="flex-group-item">
          <label>{t("release-app.add.form.fields.repository")}</label>
          {!isFetching && repositories && (
            <RepositoryPicker
              initialValue={releaseApp?.repositoryId}
              workspace={workspace}
              onInitialization={onInitialRepositorySelect}
              onSelect={handleRepositorySelect}
              repositories={repositories as Repository[]}
              errorMessage={getErrorMessage("repositoryId")}
            />
          )}
        </div>
        <div className="flex-group-item">
          <label>{t("release-app.add.form.fields.branch")}</label>
          <BranchPicker
            branches={
              !isFetchingBranches && branches && branches.branches
                ? branches.branches
                : []
            }
            onSelect={(selectedBranch) =>
              setValue("branch", selectedBranch, { shouldValidate: true })
            }
            value={watchBranch}
            errorMessage={getErrorMessage("branch")}
          />
        </div>
      </div>

      <Textarea
        label={t("release-app.add.form.fields.buildScript")}
        name="buildScript"
        errorMessage={getErrorMessage("buildScript")}
      />
      <Textarea
        label={t("release-app.add.form.fields.runScript")}
        name="runScript"
        errorMessage={getErrorMessage("runScript")}
      />
      <NumberInput
        label={t("release-app.add.form.fields.port")}
        name="port"
        errorMessage={getErrorMessage("port")}
      />
      <TextInput
        label={t("release-app.add.form.fields.sourcePath")}
        name="sourcePath"
        errorMessage={getErrorMessage("sourcePath")}
      />
      <div className="checkbox-wrapper">
        <input type="checkbox" {...register("isPublic")} />
        <label htmlFor="isPublic">
          {t("release-app.add.form.fields.isPublic")}
        </label>
      </div>

      <Textarea
        label={t("release-app.add.form.fields.extensionConfig.label")}
        name="extensionConfig"
        errorMessage={getErrorMessage("extensionConfig")}
        placeholder={t(
          "release-app.add.form.fields.extensionConfig.placeholder",
        )}
      />
      <Textarea
        label={t("release-app.add.form.fields.proxyConfig.label")}
        name="proxyConfig"
        errorMessage={getErrorMessage("proxyConfig")}
        placeholder={t("release-app.add.form.fields.proxyConfig.placeholder")}
      />

      <EnvironmentVariablesInput
        addButtonLabel={t("release-app.add.form.fields.envVar.button")}
        errorMessage={getErrorMessage("envVars")}
        fields={fields}
        inputName="envVars"
        onAdd={() => append({ name: "", value: "" })}
        onRemove={(index) => remove(index)}
        title={t("release-app.add.form.fields.envVar.title")}
      />

      <div className="actions-wrapper">
        {releaseApp && (
          <Button
            label={t("release-app.delete.button.label", {
              appModule: releaseApp.name,
            })}
            className="delete-button"
            severity="danger"
            type="button"
            variant="textOnly"
            onClick={handleDelete}
          />
        )}
        <Button
          label={t("release-app.add.form.fields.cancel")}
          type="button"
          onClick={handleCancel}
          severity="secondary"
          variant="outlined"
        />
        <SubmitButton
          label={
            releaseApp
              ? t("release-app.update.button.label")
              : t("release-app.add.form.fields.submit")
          }
          loading={isLoading}
          disabled={isFetching}
        />
      </div>
    </>
  );
};

export default AddReleaseAppFormFields;
