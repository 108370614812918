import { DEFAULT_PATHS } from "@dzangolab/react-user";

const ROUTES = {
  ABOUT: "/about",
  CHANGE_PASSWORD: DEFAULT_PATHS.CHANGE_PASSWORD,
  CONFIGURE_APPS: "/workspaces/:id/configure",
  DOCS_PLAYGROUND: "/docs-playground",
  HOME: "/",
  LOGIN: DEFAULT_PATHS.LOGIN,
  PROFILE: DEFAULT_PATHS.PROFILE,
  SERVICES: "/workspaces/:workspaceSecondaryId/services",
  SIGNUP: DEFAULT_PATHS.SIGNUP,
  WORKSPACE_CREATE: "/workspaces/new",
  WORKSPACE_GLOSSARY: "/workspaces/:workspaceSecondaryId/glossary",
  WORKSPACE_GLOSSARY_START: "/workspaces/:workspaceSecondaryId/glossary/start",
  WORKSPACE_GLOSSARY_ADD_FIRST_TERM:
    "/workspaces/:workspaceSecondaryId/glossary/:id/add-first-term",
  WORKSPACE_GLOSSARY_NEW: "/workspaces/:workspaceSecondaryId/glossary/new",
  WORKSPACE_GLOSSARY_ENTRY_EDIT:
    "/workspaces/:workspaceSecondaryId/glossary/:glossaryId/entry/:entryId/edit",
  WORKSPACE_GLOSSARY_ENTRY_NEW:
    "/workspaces/:workspaceSecondaryId/glossary/:id/entry/new",
  WORKSPACE_DATA_MODEL:
    "/workspaces/:workspaceSecondaryId/releases/:releaseSlug/data-model",
  WORKSPACE_DOCS:
    "/workspaces/:workspaceSecondaryId/releases/:releaseSlug/docs",
  WORKSPACE_DOCUMENT:
    "/workspaces/:workspaceSecondaryId/releases/:releaseSlug/docs/*",
  WORKSPACE_DOCUMENT_TYPE_CREATE: "/workspaces/:id/docs/types/create",
  WORKSPACE_DOCUMENT_TYPE_EDIT: "/workspaces/:workspaceId/docs/types/:id/edit",
  WORKSPACE_RELEASE:
    "/workspaces/:workspaceSecondaryId/releases/:releaseSecondaryId",
  WORKSPACE_RELEASES: "/workspaces/:workspaceSecondaryId/releases",
  WORKSPACE_REPOSITORIES: "/workspaces/:workspaceSecondaryId/repositories",
  WORKSPACE_REPOSITORY_CREATE:
    "/workspaces/:workspaceSecondaryId/repositories/new",
  WORKSPACE_REPOSITORY_EDIT:
    "/workspaces/:workspaceSecondaryId/repositories/:id/edit",
  WORKSPACE_REPOSITORY_DETAIL:
    "/workspaces/:workspaceSecondaryId/repositories/:id",
  WORKSPACE_RELEASE_TYPE: "/workspaces/:workspaceSecondaryId/release-type",
  WORKSPACE_RELEASE_TYPE_CREATE:
    "/workspaces/:workspaceSecondaryId/release-types/create",
  WORKSPACE_RELEASE_TYPE_EDIT:
    "/workspaces/:workspaceSecondaryId/release-types/:id/edit",
  WORKSPACE_SETTINGS_GENERAL:
    "/workspaces/:workspaceSecondaryId/settings/general",
  WORKSPACE_SETTINGS_GLOSSARY:
    "/workspaces/:workspaceSecondaryId/settings/glossary",
  WORKSPACE_SETTINGS_DOCUMENTATION:
    "/workspaces/:workspaceSecondaryId/settings/docs",
  WORKSPACE_TEAM: "/workspaces/:workspaceSecondaryId/team",
  WORKSPACES: "/workspaces",
};

enum APP_CONFIG_STEPS {
  "GENERAL" = "General",
  "APIS" = "APIs",
  "SERVICES" = "Services",
  "REACT_APP" = "React app",
  "REACT_ADMIN" = "React admin",
}

enum APP_TYPES {
  "REACT_ADMIN" = "reactAdmin",
  "REACT_APP" = "react",
  "FASTIFY_API" = "fastifyApi",
}

enum STATUS_TAG_COLOR {
  "RUNNING" = "green",
  "STOPPED" = "gray",
  "ERROR" = "red",
}

const RELEASE_SECTIONS = {
  APIS: "apis",
  APPS: "apps",
  SERVICES: "services",
};

const RELEASE_SERVICE_ALREADY_ADDED = "SERVICE_ALREADY_ADDED";
const RELEASE_ALREADY_EXISTS = "ERROR_RELEASE_ALREADY_EXISTS";

const DATABASE_SERVICE_TYPE = "Database";
const EMAIL_SERVICE_TYPE = "Email";
const FILE_STORAGE_SERVICE_TYPE = "File storage";
const SERVICE_TYPES = [
  DATABASE_SERVICE_TYPE,
  EMAIL_SERVICE_TYPE,
  FILE_STORAGE_SERVICE_TYPE,
];

const SUPPORTED_GLOSSARY_LOCALES = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "French",
    value: "fr",
  },
  {
    label: "Spanish",
    value: "es",
  },
];

const RELEASE_STATUS_APPROVED = "approved";
const RELEASE_STATUS_CLOSED = "closed";

const AUTO_CLOSE_TOAST_DURATION_MS = 60000;

const EDGE_TYPES = {
  SERVICE_EDGE: "serviceEdge",
};

export {
  APP_CONFIG_STEPS,
  APP_TYPES,
  AUTO_CLOSE_TOAST_DURATION_MS,
  DATABASE_SERVICE_TYPE,
  EDGE_TYPES,
  EMAIL_SERVICE_TYPE,
  FILE_STORAGE_SERVICE_TYPE,
  RELEASE_ALREADY_EXISTS,
  RELEASE_SECTIONS,
  RELEASE_SERVICE_ALREADY_ADDED,
  RELEASE_STATUS_APPROVED,
  RELEASE_STATUS_CLOSED,
  ROUTES,
  SERVICE_TYPES,
  STATUS_TAG_COLOR,
  SUPPORTED_GLOSSARY_LOCALES,
};
