import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Repository } from "core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";

import RepositoryFormFields from "./RepositoryFormFields";
import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";
import {
  useCreateRepositoryMutation,
  useUpdateRepositoryMutation,
} from "../../../redux/apis/repositories";

interface Properties {
  repository?: Repository;
}

export interface FormFields {
  name: string;
  url: string;
  username: string;
  accessToken: string;
}

interface ErrorResponse {
  error: {
    status: number;
    data: {
      error: string;
      message: string;
      errors?: {
        name?: string;
        url?: string;
      };
    };
  };
}

interface RepositoryResponse {
  data: Repository;
}

interface Credential {
  username?: string;
  accessToken?: string;
}

const checkCredentialsInURL = (url: string) => {
  const regex = /https?:\/\/([^:]+):([^@]+)@/;

  return regex.test(url);
};

const cleanCredential = ({ username, accessToken }: Credential) => {
  const credential: Credential = {};

  if (!username && !accessToken) {
    return null;
  }

  if (username) {
    credential.username = username;
  }

  if (accessToken) {
    credential.accessToken = accessToken;
  }

  return credential;
};

const RepositoryForm: React.FC<Properties> = ({ repository }) => {
  const { t } = useTranslation("repositories");
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();

  const [triggerAdd, { isLoading: isCreating }] = useCreateRepositoryMutation();
  const [triggerUpdate, { isLoading: isUpdating }] =
    useUpdateRepositoryMutation();

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>();

  const repositoryValidationSchema = z.object({
    name: z.string().min(1, t("form.validations.name.required")),
    url: z
      .string()
      .min(1, t("form.validations.url.required"))
      .startsWith("https://", t("form.validations.url.startsWith"))
      .regex(
        /^https:\/\/([\w\-\.]+)\/([\w\-\/\.]+)\/([\w\-]+)(\.git)?\/?$/,
        t("form.validations.url.invalidURL"),
      )
      .refine(
        (url) => !checkCredentialsInURL(url),
        t("form.validations.url.includesCredentials"),
      ),
    username: z.string(),
    accessToken: z.string(),
  });

  const defaultValues = {
    name: repository?.name || "",
    url: repository?.url || "",
    username: repository?.credential?.username || "",
    accessToken: repository?.credential?.accessToken || "",
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSubmit = async (data: FormFields) => {
    if (!workspace) return;

    const { accessToken, name, url, username } = data;
    let response: RepositoryResponse | ErrorResponse;

    try {
      if (!repository) {
        response = (await triggerAdd({
          name,
          url,
          credential: cleanCredential({ username, accessToken }),
          branch: null,
          workspaceId: workspace.id,
        })) as RepositoryResponse | ErrorResponse;
      } else {
        response = (await triggerUpdate({
          id: repository.id,
          data: {
            ...repository,
            name,
            url,
            credential: cleanCredential({ username, accessToken }),
          },
        })) as RepositoryResponse | ErrorResponse;
      }

      if ("data" in response) {
        const successMessage = !repository
          ? t("form.messages.success.create")
          : t("form.messages.success.update");

        toast.success(successMessage);
        navigate(`/workspaces/${workspace.slug}/repositories`);
      } else if ("error" in response) {
        handleErrorResponse(response.error);
      }
    } catch {
      toast.error(t("form.messages.error.default"));
    }
  };

  const handleErrorResponse = (error: ErrorResponse["error"]) => {
    if (error.data.error === "ERROR_REPOSITORY_ALREADY_EXISTS") {
      setFieldErrors(error.data.errors);
    } else {
      const errorMessage = !repository
        ? t("form.messages.error.create")
        : t("form.messages.error.update");

      toast.error(errorMessage);
    }
  };

  return (
    <Provider
      className="repository-form"
      values={defaultValues}
      validationSchema={repositoryValidationSchema}
      onSubmit={handleSubmit}
      mode="onChange"
    >
      <RepositoryFormFields
        isLoading={isCreating || isUpdating}
        handleCancel={handleCancel}
        repository={repository}
        fieldErrors={fieldErrors}
      />
    </Provider>
  );
};

export default RepositoryForm;
