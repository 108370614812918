import { useTranslation } from "@dzangolab/react-i18n";
import {
  Button,
  TDataTable as DataTable,
  TableColumnDefinition,
  Tag,
} from "@dzangolab/react-ui";
import { BranchMatchingRule, ReleaseType, Workspace } from "core";
import React, { useState } from "react";

import BranchRuleFormModal from "../../../components/BranchRuleFormModal";

interface Properties {
  branchRules?: BranchMatchingRule[];
  isLoading: boolean;
  releaseTypes?: ReleaseType[];
  repositoryId: number;
  ruleTypes?: { id: number; name: string }[];
  workspace: Workspace;
}

const BranchRules: React.FC<Properties> = ({
  branchRules,
  isLoading,
  releaseTypes,
  repositoryId,
  ruleTypes,
  workspace,
}) => {
  const { t } = useTranslation("repositoryDetails");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBranchRule, setSelectedBranchRule] =
    useState<BranchMatchingRule>();

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedBranchRule(undefined);
  };

  const editActionHandler = async (branchRule: BranchMatchingRule) => {
    setSelectedBranchRule(branchRule);
    openModal();
  };

  const columns: Array<TableColumnDefinition<any>> = [
    {
      accessorKey: "releaseTypeName",
      header: t("table.columns.releaseType"),
      minWidth: "10rem",
      maxWidth: "10rem",
      width: "10rem",
    },
    {
      accessorKey: "ruleTypeName",
      header: t("table.columns.ruleType"),
      minWidth: "10rem",
      maxWidth: "10rem",
      width: "10rem",
    },
    {
      accessorKey: "includePatterns",
      header: t("table.columns.includePatterns"),
    },
    {
      accessorKey: "excludePatterns",
      header: t("table.columns.excludePatterns"),
      minWidth: "15rem",
      maxWidth: "15rem",
      width: "15rem",
    },
    {
      accessorKey: "isActive",
      header: t("table.columns.active"),
      minWidth: "10rem",
      maxWidth: "10rem",
      width: "10rem",
      cell: ({
        row: { original },
      }: {
        row: { original: BranchMatchingRule };
      }) => {
        const isActive = original.isActive;

        return (
          <Tag
            label={isActive ? t("table.tag.yes") : t("table.tag.no")}
            color={isActive ? "green" : "red"}
          />
        );
      },
    },
  ];

  return (
    <>
      <Button
        variant="filled"
        className="branch-rule-add-btn"
        iconLeft="pi pi-plus"
        onClick={() => openModal()}
      />
      <DataTable
        columns={columns}
        className="repository-rules-table"
        data={branchRules || []}
        isLoading={isLoading}
        dataActionsMenu={{
          actions: [
            {
              label: t("table.actions.edit"),
              onClick: (rowData) => editActionHandler(rowData),
            },
          ],
        }}
      />

      {releaseTypes && ruleTypes && (
        <BranchRuleFormModal
          onHide={closeModal}
          visible={isModalVisible}
          branchRule={selectedBranchRule}
          releaseTypes={releaseTypes}
          ruleTypes={ruleTypes}
          repositoryId={repositoryId}
          workspaceId={workspace.id}
        />
      )}
    </>
  );
};

export default BranchRules;
